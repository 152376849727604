<template>
  <rb-modal
    :options="{
      labels: {
        buttons: {
          apply: note.id ? 'Save changes' : (note || title? 'Publish note':'Create note'),
          cancel: 'Cancel'
        }
      }
    }"
    :class="$style.Modal"
    @hide="onSubmit()"
    @cancel="cancel()"
  >
    <div slot="header">
      <div :class="$style.Header">
        {{ note.id ? "Edit note" : "Create note" }}
      </div>

      <p :class="$style.Text">
        {{
          note.id
            ? "Edit the note below"
            : "Create a note to be displayed on the selected hotels"
        }}
      </p>
    </div>
    <div
      slot="body"
      :class="$style.Body"
    >
      <label :class="$style.Label">Select color</label>
      <div :class="$style.Colors">
        <color-picker
          v-model="color"
          label="Open picker"
          :classes="$style.ColorPicker"
        />
        <span :class="$style.Text">- OR -</span>
        <div :class="$style.ColorOptions">
          <div
            v-for="c in colors"
            :key="c"
            :class="{ [$style.Color]: true, [$style.active]: c === color }"
            :style="{ backgroundColor: c }"
            @click="color = c"
          />
        </div>
      </div>
      <label :class="$style.Label">Title</label>
      <input
        id="title"
        v-model="title"
        type="text"
        name="title"
        class="rbInput"
        autofocus
        maxlength="100"
        placeholder="Note title"
      >
      <label :class="$style.Label">Note</label>
      <textarea
        v-model="text"
        :class="$style.TextAreaInput"
        placeholder="Write your note here..."
      />
    </div>
  </rb-modal>
</template>

<script>
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
import RbModal from 'vRoot/_core/RbModal.vue';
import ColorPicker from 'vRoot/_core/RbColorPicker.vue';
import hotelDirectoryService from '../../hotel-directory-service';


const colors = [
  '#FFCDD2',
  '#F8BBD0',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',
  '#BBDEFB',
  '#B3E5FC',
  '#B2EBF2',
  '#B2DFDB',
  '#C8E6C9',
  '#D32F2F',
  '#C2185B',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0288D1',
  '#0097A7',
  '#00796B',
  '#388E3C'
];

export default {
  name: 'CreateOrEditNoteDialog',
  components: { RbModal, ColorPicker },
  mixins: [DialogMixin],
  props: {
    note: {
      type: Object,
      default: () => ({})
    },
    propertyIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      color: this.note && this.note.color ? this.note.color : colors[0],
      text: this.note ? this.note.note : '',
      title: this.note ? this.note.title : '',
    };
  },
  computed: {
    colors() {
      return colors.indexOf(this.color) > -1
        ? colors
        : [this.color, ...colors].filter(Boolean).slice(0, colors.length);
    }
  },
  methods: {
    onSubmit() {
      const note = { id: this.note.id, note: this.text, color: this.color, title: this.title };
      const promise = this.note.id ? this.save(note) : this.create(note);
      promise.then(this.hide);
    },
    save(note) {
      return hotelDirectoryService.saveDirectoryNote(note).then(() => note);
    },
    create(note) {
      return hotelDirectoryService
        .createDirectoryNote(note, this.propertyIds)
        .then(({ data }) => data);
    }
  }
};
</script>

<style lang="stylus" module>
.Modal {
  overflow: visible;
}

.Header {
  font-weight : bold;
  font-size : 14px;
  color : #37474F;
}

.Text {
  font-size : 12px;
  color : #78909C;
  margin: 5px 0;
}

.Color {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor pointer;
  border: 2px solid transparent;
}

.Color.active {
  border: 2px solid #263238;
}

.Colors {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.ColorOptions {
  display: flex;
  gap: 6px;
  align-items: center;
  max-width: 300px;
  flex-wrap wrap;
}

.Body {
  width 500px;
}

.ColorPicker {
  width: 130px;
  height 54px;
  min-height 54px;
  border 1px solid #546f7a4f;
}

.Colors > span {
  white-space nowrap
}

.Label {
  composes: Text;
  margin: 10px 0 5px;
}

.TextAreaInput {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;

  box-sizing: border-box;

  white-space: pre-wrap;
  display block;

  min-height 200px;
  width: 100%;
  border: 1px solid #cce;
  padding: 5px;
  border-radius: 5px;

  &::placeholder {
    color: #B0BEC5;
  }
}
</style>
