<script>
  import {onMount} from 'svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';

  import RateAccessCodeEditor from 'root/rfp-hotel/main/bid/pages/final-agreement/RateAccessCodeEditor.svelte';

  export let bidsIds, cancel, hide;

  let stateReady, rateAccessCode = '';

  onMount(() => {
    if(bidsIds.length === 1) {
      notificationUtils().onLoad(() => mainAPI().getBidRateAccessCode(bidsIds[0]))
        .then(r => {
          rateAccessCode = r.data || '';
          stateReady = true;
        });
    } else {
      rateAccessCode = '';
      stateReady = true;
    }
  });

  function save(){
    notificationUtils().onSave(() => mainAPI().setBidsRateAccessCode(bidsIds, rateAccessCode))
      .then(hide);
  }

</script>

<RateAccessCodeEditor {stateReady}
                      bind:rateAccessCode
                      {cancel}
                      {save}
/>
