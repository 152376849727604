<template>
  <div
    class="hotel"
    :class="{ open: open, excluded: !included }"
  >
    <!--    {{ previewing }} {{ updating }}-->
    <div
      v-if="!previewing"
      class="box"
    >
      <check-box
        v-if="!updating"
        :value="included"
        :check-box-value="true"
        class="dark-icon"
        @input="$emit('toggle')"
        @click.stop
      />
      <rbv-loading
        v-else
        rb-class="small"
      />
    </div>
    <template v-if="!open">
      <div style="position: relative">
        <hotel-image
          class="image"
          :image="hotel.company.image ? hotel.company.image.url : ''"
          @click="toggle"
        />

        <div
          v-if="isClosed"
          class="hotelClosed"
        >
          CLOSED
        </div>
      </div>
      <div
        class="columns"
        @click="toggle"
      >
        <div class="main column sm">
          <div class="hotel-name-container">
            <div
              class="hotel-name label"
              :class="{ isClosed: isClosed }"
            >
              {{ hotel.company.name }}
            </div>

            <div
              v-if="sustainability"
              class="sustainability"
            >
              <img
                :src="sustainability"
                alt="Sustainable"
                loading="eager"
              >
            </div>
          </div>
          <rating
            v-if="hasColumn('rating')"
            class="data"
            :rate="hotel.rating"
          >
            &nbsp;&middot;&nbsp;
            <template v-if="hasColumn('address')">
              {{ address }}
            </template>
          </rating>
          <div
            v-else-if="hasColumn('address')"
            class="data hotel-type"
          >
            <div class="address">{{ address }}</div>
          </div>
        </div>
        <template v-for="column in columns">
          <div
            v-if="
              hasColumn(column.id) || (column.id === 'code' && includedCode)
            "
            :key="column.id"
            class="column"
            :class="column.id"
          >
            <span
              v-if="column.id !== 'amenities' && column.id !== 'code'"
              class="label"
            >
              {{ column.text }}
            </span>
            <span
              v-if="column.id === 'distanceMi'"
              class="data"
            >
              {{ getValue(column.key).toFixed(2) }} Mi
            </span>
            <amenities
              v-else-if="column.id === 'amenities'"
              class="data"
              :amenities="amenities"
            />
            <template v-else-if="column.id === 'code'">
              <span class="label"> {{ includedCode.text }} </span>
              <span class="data"> {{ includedCodeValue }}&nbsp;</span>
            </template>
            <span
              v-else
              class="data"
            >
              {{ getValue(column.key) || "-" }}
            </span>
          </div>
        </template>
      </div>
      <div
        class="sm rate column"
        @click="toggle"
      >
        <div class="label">
          <template v-if="bestRate && view.showRateTypes">
            {{ bestRate.type }}
          </template>
          Pref. rate
        </div>
        <div class="data">
          <span
            v-if="bestRate"
            class="value"
          >
            {{ bestRate.rate }} {{ hotel.currency }}
          </span>
          <span
            v-else-if="showDynamicRate"
            class="value bestRate"
          >
            <span> {{ hotel.dynamicRate }}%</span>
            off Best Available
          </span>
          <i class="material-icons">keyboard_arrow_down</i>
          <span
            v-if="bestRate && showDynamicRate && hotel.dynamicRate"
            class="dyn"
          >
            {{ hotel.dynamicRate }}% off best available
          </span>
        </div>
      </div>
      <div
        v-if="hotel.notes && hotel.notes.length"
        class="notes"
      >
        <hotel-note
          v-for="note in hotel.notes"
          :key="note.id"
          :note="note"
          :editable="false"
        />
      </div>
    </template>
    <template v-else>
      <span class="actions">
        <i
          class="material-icons screen"
          @click="imageExpanded = !imageExpanded"
        >
          {{ !imageExpanded ? "fullscreen" : "fullscreen_exit" }}
        </i>
        <i
          class="material-icons"
          @click="toggle"
        >close</i>
      </span>

      <div style="position: relative">
        <img
          v-if="hotel.company.logo"
          class="logo"
          :src="`images/logos/${hotel.company.logo}`"
          alt=""
          loading="lazy"
        >
        <hotel-image
          class="image"
          :class="{ expanded: imageExpanded }"
          :image="hotel.company.image ? hotel.company.image.url : ''"
          @click="toggle"
        />
        <div
          v-if="isClosed"
          class="hotelClosed"
        >
          CLOSED
        </div>
      </div>
      <div class="columns">
        <div class="main column">
          <div>
            <div class="hotel-name-container">
              <div
                class="hotel-name label"
                :class="{ isClosed: isClosed }"
              >
                {{ hotel.company.name }}
              </div>
              <div
                v-if="sustainability"
                class="sustainability"
              >
                <img
                  :src="sustainability"
                  alt="Sustainable"
                  loading="eager"
                >
              </div>
            </div>

            <rating
              v-if="hasColumn('rating')"
              class="data"
              :rate="hotel.rating"
            >
              &nbsp;&middot;&nbsp;
              <template v-if="hasColumn('address')">
                {{ address }}
              </template>
              <template v-if="hasColumn('address') && hasColumn('phone')">
                &nbsp;&middot;&nbsp;
              </template>
              <template v-if="hasColumn('phone')">
                {{ hotel.company.phone }}
              </template>
            </rating>
            <div
              v-else
              class="data hotel-type"
            >
              <div class="address">
                &nbsp;&middot;&nbsp;
                <template v-if="hasColumn('address')">
                  {{ address }}
                </template>
                <template v-if="hasColumn('address') && hasColumn('phone')">
                  &nbsp;&middot;&nbsp;
                </template>
                <template v-if="hasColumn('phone')">
                  {{ hotel.company.phone }}
                </template>
              </div>
            </div>
          </div>
          <a
            :href="directionsLink"
            target="_blank"
            class="button"
          >
            <i class="material-icons">location_on</i>
            Directions
            <template v-if="hasColumn('distanceMi')">
              ({{ parseFloat(hotel.analytics.distanceMi).toFixed(2) }} Mi)
            </template>
          </a>
        </div>
        <div
          v-if="hasColumn('amenities')"
          class="column amenities inline"
        >
          <div
            v-for="amenity in amenitiesData"
            :key="amenity.abbr"
            class="amenity"
            :class="{ active: amenities.indexOf(amenity.abbr) >= 0 }"
          >
            <i class="material-icons check">
              {{ amenities.indexOf(amenity.abbr) >= 0 ? "check" : "block" }}
            </i>
            <span class="label"> {{ amenity.name }} </span>
          </div>
        </div>
        <div
          v-if="hotel.notes && hotel.notes.length"
          class="openedNotes"
        >
          <div class="title">Notes:</div>
          <hotel-note
            v-for="note in hotel.notes"
            :key="note.id"
            :note="note"
            :editable="false"
          />
        </div>
        <div class="season-rates column">
          <div
            v-for="(season, index) in hotel.seasons"
            :key="index"
            class="season"
          >
            <div class="title">
              <span class="label">
                Season {{ index + 1 }}
                <template v-if="season.start && season.end">
                  ({{ formatSeasonDate(season.start) }} -
                  {{ formatSeasonDate(season.end) }})
                </template>
              </span>
              <span class="data"> {{ hotel.currency }} </span>
            </div>
            <div class="rates">
              <template v-for="(tRate, key) in season.rates">
                <div
                  v-if="showRate(key)"
                  :key="key"
                  class="rate"
                >
                  <span class="label">
                    {{ rateLabel(key) }}
                    <template
                      v-if="
                        view.showRateTypes &&
                          season.rateTypes &&
                          season.rateTypes[key]
                      "
                    >({{ season.rateTypes[key] }})</template>
                  </span>
                  <span class="data"> {{ parseFloat(tRate).toFixed(2) }} </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="showDynamicRate && hotel.dynamicRate"
          class="column"
        >
          <span class="label">Dynamic Rate</span>
          <span class="data"> {{ hotel.dynamicRate }}% Off Best Available</span>
        </div>
        <template v-for="column in columns.filter(col => col.expanded)">
          <div
            v-if="
              hasColumn(column.id) || (column.id === 'code' && includedCode)
            "
            :key="column.id"
            class="column"
            :class="{ [column.id]: true }"
          >
            <span
              v-if="column.id === 'code' && includedCode"
              class="label"
            >
              {{ includedCode.text }}
            </span>
            <span
              v-else-if="column.id === 'distanceMi'"
              class="label"
            >
              Distance from {{ hotel.travelDestination.name }}
            </span>
            <span
              v-else
              class="label"
            > {{ column.text }} </span>
            <span
              v-if="column.id === 'distanceMi'"
              class="data"
            >
              {{ get(column.key) && get(column.key).toFixed(2) }} Mi
            </span>
            <span
              v-else-if="column.id === 'code'"
              class="data"
            >
              {{ includedCodeValue }}
            </span>
            <span
              v-else
              class="data"
            >
              {{ getValue(column.key) || "-" }}
            </span>
          </div>
        </template>
        <div
          v-if="
            view.showBlackoutDates &&
              hotel.blackoutDates &&
              hotel.blackoutDates.length
          "
          class="blackoutDates column"
        >
          <div>Blackout Dates</div>
          <div
            v-for="blackoutDate in hotel.blackoutDates"
            :key="blackoutDate.start"
            class="blackoutDate"
          >
            {{ formatSeasonDate(blackoutDate.start) }}
            -
            {{ formatSeasonDate(blackoutDate.end) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Rating from 'vRoot/hotel-directory/_core/rating.vue';
import Amenities, {
  amenities
} from 'vRoot/hotel-directory/_core/amenities.vue';
import CheckBox from 'vRoot/_core/RbCheckboxButton.vue';
import RbvLoading from 'vRoot/_core/RbvLoading.vue';

import { get } from 'lodash';
import moment from 'moment';

import icon from 'root/assets/icons/rate.svg';
import greenLeafImg from 'root/assets/icons/green-leaf.svg';
import HotelImage from 'vRoot/hotel-directory/modules/preview/hotel-image.vue';
import HotelNote from './hotelNote.vue';

export default {
  name: 'HotelDirectoryHotel',
  components: {HotelImage, Rating, Amenities, CheckBox, RbvLoading, HotelNote },
  props: {
    hotel: {
      type: Object,
      required: true
    },
    view: {
      type: Object,
      required: true
    },
    previewing: Boolean,
    updating: Boolean
  },
  data() {
    return {
      open: false,
      columns: [
        {
          id: 'distanceMi',
          text: 'Distance',
          key: 'analytics.distanceMi',
          expanded: true
        },
        {
          id: 'phone',
          text: 'Phone Number',
          key: 'company.phone'
        },
        {
          id: 'code',
          expanded: true
        },
        {
          id: 'amenities',
          text: 'Amenities',
          key: 'amenities'
        }
      ],
      imageExpanded: false,
      rate_icon: icon,
      propertyCodes: [
        { id: 'AMADEUS_PROPCODE', text: 'Amadeus Code', value: 'amadeuscode' },
        { id: 'APOLLO_PROPCODE', text: 'Apollo Code', value: 'apollocode' },
        { id: 'SABRE_PROPCODE', text: 'Sabre Code', value: 'sabrecode' },
        {
          id: 'WRLDSPAN_PROPCODE',
          text: 'WorldSpan Code',
          value: 'worldspancode'
        }
      ],
      amenitiesData: amenities
    };
  },
  computed: {
    amenities() {
      return this.hotel.amenities;
    },
    formattedImageUrl() {
      if (this.hotel.company.image) {
        return this.hotel.company.image.url.startsWith('http')
          ? this.hotel.company.image.url
          : `/images/entities/${this.hotel.company.image.url}`;
      }
      return '/images/hotel_placeholder.png';
    },
    included() {
      return !(
        this.view.excludedBids &&
        this.view.excludedBids.includes(this.hotel.bidId)
      );
    },
    currentSeason() {
      let season =
        this.hotel.seasons &&
        this.hotel.seasons.find(ss =>
          moment().isBetween(ss.start, ss.end, null, '[]')
        );
      season = season || (this.hotel.seasons && this.hotel.seasons[0]);
      return season;
    },
    bestRate() {
      const { rates = {}, rateTypes = {} } = this.currentSeason || {};
      const values = this.view.rates
        .map(rate => ({ value: +rates[rate], rate }))
        .filter(({ value }) => value)
        .sort((a, b) => (a.value > b.value ? 1 : -1));
      if (values.length) {
        return {
          rate: values[0].value,
          type: rateTypes[values[0].rate]
        };
      }
      return undefined;
    },
    showDynamicRate() {
      return this.showRate('DYN');
    },
    address() {
      let address = '';
      address += this.getValue('company.location.address.address1')
        ? `${this.getValue('company.location.address.address1')},`
        : '';
      address += this.getValue('company.location.address.city')
        ? ` ${this.getValue('company.location.address.city')},`
        : '';
      address += this.getValue('company.location.address.state')
        ? ` ${this.getValue('company.location.address.state')},`
        : '';
      address += this.getValue('company.location.address.countryName')
        ? ` ${this.getValue('company.location.address.countryName')}`
        : '';
      return address;
    },
    includedCode() {
      return this.propertyCodes.find(
        code => this.view.columns.indexOf(code.value) > -1
      );
    },
    includedCodeValue() {
      return (
        this.includedCode &&
        this.hotel.propertyCodes &&
        this.hotel.propertyCodes[this.includedCode.id]
      );
    },
    directionsLink() {
      const daddr = {
        latitude:
          this.hotel.travelDestination.location.coordinates.lat ||
          this.hotel.travelDestination.location.coordinates.latitude,
        longitude:
          this.hotel.travelDestination.location.coordinates.lng ||
          this.hotel.travelDestination.location.coordinates.longitude
      };

      let url = `://maps.google.com/maps?daddr=${makeCoordinates(
        this.hotel.company.coordinates
      )}&saddr=${makeCoordinates(daddr)}`;

      if (
        navigator.platform.indexOf('iPhone') !== -1 ||
        navigator.platform.indexOf('iPod') !== -1 ||
        navigator.platform.indexOf('iPad') !== -1
      ) {
        url = `maps${url}`;
      } else {
        url = `https${url}`;
      }

      return url;

      function makeCoordinates({ latitude, longitude }) {
        return `${latitude},${longitude}`;
      }
    },
    isClosed() {
      return (
        this.hotel.company.status &&
        this.hotel.company.status.value === 'OBSOLETE'
      );
    },
    sustainability() {
      return this.hotel.analytics.sustainability &&
        this.view.columns.indexOf('sustainability') !== -1
        ? greenLeafImg
        : false;
    }
  },
  methods: {
    hasColumn(id) {
      return this.view.columns.indexOf(id) >= 0;
    },
    toggle() {
      this.open = !this.open;
    },
    getValue(path) {
      return this.get(path, '-');
    },
    get(path, def) {
      return get(this.hotel, path, def);
    },
    rateLabel(rate) {
      const [roomType, rateType] = rate.split('');
      const roomTypes = { S: 'Standard', U: 'Upgraded', D: 'Deluxe' };
      const rateTypes = { S: 'Single', D: 'Double' };
      return `${roomTypes[roomType]} ${rateTypes[rateType]}`;
    },
    formatSeasonDate(date) {
      return moment(date).format('MM.DD.YY');
    },
    showRate(rate) {
      return this.view.rates.indexOf(rate) > -1;
    }
  }
};
</script>
<style lang="stylus">

.hotel{
  border-radius 5px;
  background #fff;
  // overflow: hidden;
  display: flex;
  position relative;
  transition all .3s;
  transition height 0s;

  margin 3px 0;
  height: 94px;
  min-width: 50vw;

  &.excluded {
    background: rgba(255, 255, 255, .7);
  }

  .box {
    position: absolute;
    height: 18px;
    width: 18px;
    background: #fff
    z-index: 2;

    .rbCheckboxOptionButton {
      padding: 2px 0;
    }

    .rbv-loading {
      height: 18px;
      padding: 0;

      & > div {
        padding: 0 2px;
      }
    }
  }

  .image{
    height: 92px;
    width: 90px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    margin 1px;
    background #fff;
  }

.hotelClosed {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(241, 90, 36);
  color: white;
  line-height: 26px;
  margin-right: 1px;
  font-weight: bold;
  font-size: 16px;
}


.columns{
  flex 1;
  min-width 0;
  color: #323c43;
  overflow hidden;
  white-space nowrap;
  text-overflow ellipsis;
  display flex;
}

  .column{
    padding 10px 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing border-box;
    display flex;
    flex-direction column;
    justify-content space-evenly;
    border-right 1px solid #ECEFF1;


    &.main{
      width 400px;
      flex 1;
      min-width 150px;
    }

    &.amenities{
      width 240px;
      min-width 240px;
    }

    &.phone{
      min-width 130px;
      width 130px;
    }

    .label{
      color #90A4AE;
      text-transform uppercase;
      font-size 12px;
    }

  .hotel-name-container {
    display: flex;
  }

  .hotel-name {
    flex: 0 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size 17px;
    color #37474F;

    &.isClosed {
      color: #F15A24;
      text-decoration: line-through;
    }
  }

  .sustainability {
    flex: 0 0 auto;
    padding-left: 10px;
    color: green;

    img {
      height: 20px;
      line-height: 20px;
      vertical-align: bottom;
    }
  }

  .data{
    display: flex;
    align-items: center;
    overflow hidden;
    color #546E7A;
    font-size 13px;

      &.hotel-type{
        justify-content flex-start;
      }

      .address{
        padding: 0;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:not(.open){
    cursor pointer;

    .rate{
      position: relative;

      .value{
        color #546E7A;
        font-size 20px;
        font-weight 600;

        &.bestRate{
          font-size 10px;

          span{
            font-size: 20px;
          }
        }
      }
      i{
        color #90A4AE;
      }
      .data {
        justify-content flex-end;
      }

      .dyn{
        position absolute;
        right 0;
        bottom 0;
        padding-right: 10px;
      }
    }


    .column:not(.main) {
      width 140px;

      .label{
        text-align center;
      }

      .data{
        justify-content center;
      }

      &.rate{
        max-width: 212px;
        min-width: 212px;
        align-items: flex-end;
        padding: 10px;
      }

      &.distanceMi{
        max-width: 88px;
        min-width: 88px;
      }

      &.code{
        max-width: 150px;
        min-width: 150px;
      }
    }

    @media screen and (max-width: 751px) {
      border-radius 0 !important;
      .image{
        border-radius 0 !important;
      }

      .column:not(.sm){
        display none;
      }

      .rate{
        min-width 0 !important;
        width fit-content !important;
        .label{
          white-space normal;
          text-align right;
        }
      }

      .main{
        min-width 0;
      }
    }
  }

  &.open{
    height: auto;

    .image{
      height: 200px;
      width: 200px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
      transition height .3s;
    }

  .hotelClosed {
    line-height: 48px;
    font-size: 20px;
  }

  .columns{
    flex-direction column;
    overflow visible;

      .column{
        border-right 0;
        white-space normal;
        width auto;
        padding-right 25px;

        &.main{
           display: flex;
           flex-direction: row;
           align-items: center;
           justify-content: space-between;

          .button {
            i.material-icons{
              color: #e0161f;
            }
          }

        .hotel-name-container {
          display: block;
        }

        .hotel-name{
          white-space unset;
          display: inline;
        }
        .sustainability {
          display: inline;
        }
      }

        &:not(.main){
          flex-direction row;
          justify-content space-between;
        }

        &.season-rates{
          padding: 0 10px;
          display: flex;
          flex-wrap: wrap;

          .season{
            min-width 240px;
            flex: 1;
            margin 5px;

            .title{
              font-weight bold;
              justify-content space-between;
              display flex;
              align-items center;
              padding 5px;
            }

            .rates{
              border: 1px solid #eceff1;
              border-radius: 3px;

              .rate {
                border-bottom 1px solid #ECEFF1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px;

                &.best .label{
                  color #40b6a7;
                }

                .label{
                  display: flex;
                  align-items: center;
                }

                .data{
                  font-size 13px;
                  font-weight 600;
                }

                &.best{
                  .data{
                    font-size 20px;
                  }
                }
              }
            }
          }
        }

        &.amenities.inline{
          flex-wrap: wrap;
          justify-content: flex-start !important;
          padding: 5px 15px;

          .amenity{
            padding: 5px 0;
            flex: unset;
            width: fit-content;
            border: none;

            & + .amenity {
              margin-left 5px;
            }

            i.material-icons.check{
              font-size: 15px;
            }

            .label{
              padding 0;
            }

            &:not(.active){
              i.material-icons.check{
                color: #91a5af;
              }
            }
          }
        }

        .hotel-type {
          .name{
            overflow: visible;
            white-space normal;
          }
        }
      }
    }

    .actions{
      position: absolute;
      right: 0;
      top: 5px;

      i{
        width: 30px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 18px;

        & + i{
          margin-left 5px;
        }
      }
      .screen{
        display none;
      }
    }

    .logo{
      height: 60px;
      width: 100px;
      border-radius 0;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 10px;
      object-fit: cover;
      position absolute;
      top 0;
      left 0;
    }

    @media screen and (max-width: 501px) {
      display block;
      border-radius 0;

      .data{
        flex-wrap wrap;
      }

      .image{
        width: calc(100% - 2px);
        height 100px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 5px;

        &.expanded{
          height 300px;
        }
      }

      .logo{
        width 120px;
        height 80px;
      }

      .actions {
        i{
          background rgba(0,0,0,0.5);
          color #fff;
          padding 3px;
          border-radius 100%;
        }

        .screen{
          display inline-block;
        }
      }

      .main{
        flex-direction column;
      }
    }
  }
}

.blackoutDates {
  color #90a4ae;
  text-transform uppercase;
  font-size 12px;
  gap: 10px;

  & > :first-child {
    flex: 1;
  }
}

.notes {
  position absolute;
  bottom 2px;
  left 108px;
  display flex;
  gap 3px;
  align-items center;
  background #fff;
  padding 2px 0;
}

.openedNotes {
  display: flex;
  position: relative;
  align-items: center;
  gap: 3px;
  padding: 0 16px;
}
</style>
