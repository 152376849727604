var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rb-modal',{class:_vm.$style.Modal,attrs:{"options":{
    labels: {
      buttons: {
        apply: _vm.note.id ? 'Save changes' : (_vm.note || _vm.title? 'Publish note':'Create note'),
        cancel: 'Cancel'
      }
    }
  }},on:{"hide":function($event){return _vm.onSubmit()},"cancel":function($event){return _vm.cancel()}}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{class:_vm.$style.Header},[_vm._v(" "+_vm._s(_vm.note.id ? "Edit note" : "Create note")+" ")]),_c('p',{class:_vm.$style.Text},[_vm._v(" "+_vm._s(_vm.note.id ? "Edit the note below" : "Create a note to be displayed on the selected hotels")+" ")])]),_c('div',{class:_vm.$style.Body,attrs:{"slot":"body"},slot:"body"},[_c('label',{class:_vm.$style.Label},[_vm._v("Select color")]),_c('div',{class:_vm.$style.Colors},[_c('color-picker',{attrs:{"label":"Open picker","classes":_vm.$style.ColorPicker},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('span',{class:_vm.$style.Text},[_vm._v("- OR -")]),_c('div',{class:_vm.$style.ColorOptions},_vm._l((_vm.colors),function(c){
  var _obj;
return _c('div',{key:c,class:( _obj = {}, _obj[_vm.$style.Color] = true, _obj[_vm.$style.active] = c === _vm.color, _obj ),style:({ backgroundColor: c }),on:{"click":function($event){_vm.color = c}}})}),0)],1),_c('label',{class:_vm.$style.Label},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"rbInput",attrs:{"id":"title","type":"text","name":"title","autofocus":"","maxlength":"100","placeholder":"Note title"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}}),_c('label',{class:_vm.$style.Label},[_vm._v("Note")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:_vm.$style.TextAreaInput,attrs:{"placeholder":"Write your note here..."},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.text=$event.target.value}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }