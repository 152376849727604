<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import ModalButtons from 'root/services/modals/ModalButtons.YesNo.svelte';
  import Spinner from 'root/components/atoms/Spinner.svelte';

  export let cancel, save, stateReady, rateAccessCode = '';
</script>


<ClosableModal width="600px" close="{cancel}">
  <div slot="heading">
    <ModalTitle title="Set Bid Rate Access Code">
    </ModalTitle>
  </div>

  <div class="rb-input">
    {#if stateReady}
      <label for="rac">Rate Access Code</label>
      <input id="rac" type="text" bind:value={rateAccessCode} maxlength="20" />
    {:else}
      <div class="layout-column flex-center">
        <Spinner />
      </div>
    {/if}
  </div>

  <ModalButtons disabled="{!stateReady}"
                noAction="{cancel}"
                yesLabel="Save"
                yesIcon="save"
                yesAction="{save}"
  />
</ClosableModal>

<style lang="stylus">.rb-input {
  padding: 0 15px 15px;
}
</style>
