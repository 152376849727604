

export default {
    getManageHotelColumns,
    setManageHotelColumns,
    getManageUsersColumns,
    setManageUsersColumns,
    getManageUsersColumn,
    getDefaultManageUsersColumns,
    getDefaultManageHotelsColumns,
    getDefaultManageHotelsColumn,
}

const MANAGE_HOTEL_COLUMNS_STORE = 'hotelDirectoryManageHotelColumns';
const MANAGE_USERS_COLUMNS_STORE = 'hotelDirectoryManageUsersColumns';

const manageUsersColumns = [
  {id: 'name', text: 'User Name', key: ['firstName', 'lastName'], default: true },
  {id: 'email', text: 'Email', key: 'email', default: true },
  {id: 'category', text: 'Directory View', key: 'directoryViewId', default: true },
  {id: 'type', text: 'Type', key: 'type', default: true },
  {id: 'phone', text: 'Phone', key: 'phone' },
  {id: 'title', text: 'Job Title', key: 'jobTitle' },
  {id: 'department', text: 'Department', key: 'department' },
];

const manageHotelColumns = [
  {id: 'name', text: 'Hotel Name', key: 'company.name', style: {minWidth: '250px'} },
  {id: 'address', text: 'Address', key: 'company.address.address1', style: {minWidth: '250px'}},
  {id: 'city', text: 'City', key: 'company.address.city', style: {minWidth: '200px'}},
  {id: 'state', text: 'State/Region', key: ['company.address.state', 'company.address.region'], style: {minWidth: '200px'} },
  {id: 'distanceMi', text: 'Distance', key: 'analytics.distanceMi', style: {minWidth: '50px'}},
  {id: 'rating', text: 'Hotel Type', key: 'rating', style: {minWidth: '250px'} },
  {id: 'rates', text: 'Rates', key: 'rates', style: {minWidth: '150px'}},
  {id: 'country', text: 'Country', key: 'company.address.countryName', style: {minWidth: '100px'}},
  {id: 'phone', text: 'Hotel Phone', key: 'company.phone', style: {minWidth: '80px'}},
  {id: 'code', text: 'Property Code', key: 'propertyCodes', style: {minWidth: '150px'}},
  {id: 'wf', text: 'WIFI', key: 'wf', style: {minWidth: '100px'}, isAmenity: true },
  {id: 'ft', text: 'Fitness', key: 'ft', style: {minWidth: '100px'}, isAmenity: true },
  {id: 'ia', text: 'Internet Access', key: 'ia', style: {minWidth: '100px'}, isAmenity: true },
  {id: 'ec', text: 'Early Checkout', key: 'ec', style: {minWidth: '100px'}, isAmenity: true },
  {id: 'as', text: 'Airport Shuttle', key: 'as', style: {minWidth: '100px'}, isAmenity: true },
  {id: 'bf', text: 'Break Fast', key: 'bf', style: {minWidth: '100px'}, isAmenity: true },
  {id: 'prk', text: 'Parking', key: 'prk', style: {minWidth: '100px'}, isAmenity: true }
];

function getManageHotelColumns(){
    return JSON.parse(localStorage.getItem(MANAGE_HOTEL_COLUMNS_STORE));
}

function setManageHotelColumns(columns){
    localStorage.setItem(MANAGE_HOTEL_COLUMNS_STORE, JSON.stringify(columns));
}

function getManageUsersColumns(){
    return JSON.parse(localStorage.getItem(MANAGE_USERS_COLUMNS_STORE));
}

function setManageUsersColumns(columns){
    localStorage.setItem(MANAGE_USERS_COLUMNS_STORE, JSON.stringify(columns));
}

function getManageUsersColumn(id){
    return manageUsersColumns.find(manage => manage.id === id);
}

function getDefaultManageUsersColumns(){
    return manageUsersColumns;
}

function getDefaultManageHotelsColumns(){
    return manageHotelColumns;
}

function getDefaultManageHotelsColumn(id){
    return manageHotelColumns.find( col => col.id === id);
}
