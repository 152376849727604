export default RbvHotelDirectoryRepository

// default data for hotel directory
const columns = [
    {id: 'rating', text: 'Hotel Type', key: 'rating', default: true},
    {id: 'address', text: 'Address', key: 'company.location.address.address1', default: true},
    {id: 'distanceMi', text: 'Distance', key: 'analytics.distanceMi', default: true},
    {id: 'amenities', text: 'Amenities', key: 'amenities', default: true },
    {id: 'phone', text: 'Hotel Phone', key: 'company.phone', default: true},
    {id: 'sustainability', text: 'Sustainability', key: 'sustainability', default: false},
    {id: 'code', text: 'Property Code', key: 'propertyCode', default: false},
];

RbvHotelDirectoryRepository.$inject = ['MainServer', '$q'];
function RbvHotelDirectoryRepository(mainServer, $q){
  const $repo = this;

    $repo.getHotelDirectory =
        (directoryId) => directoryId
        ? mainServer.get(`hotel-directories/get/${directoryId}`)
        : $q.reject({status: 404});

    $repo.createHotelDirectoryWithDefaultConfigs = account => {
        return account? mainServer.post(
            `hotel-directories/create`,
            getDefaultHotelDirectory(account)
        ): $q.reject({status: 404});
    };

    $repo.updateView = (directoryId, view) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/view`,
            view
        );
    };

    $repo.createView = (directoryId, name, account) => {
        const view = createDefaultView(account, name);
        return name && mainServer.post(
            `hotel-directories/${directoryId}/create-view`,
            view
        );
    };

    $repo.deleteView = view => {
        return view && mainServer.delete(
            `hotel-directories/views/${view.id}/delete`
        );
    };

    $repo.getColumns = () => columns;

    $repo.getAllFinalAgreementHotels = (accountId) => accountId? mainServer.get(`hotel-directories/all-final-agreement-hotels/${accountId}`): $q.reject({status: 404});

    $repo.addHotels = (bids, directoryId) => {
        return directoryId && bids
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/add-hotels`, {bids}
            )
            : $q.reject({status: 404})
    };

    $repo.removeHotels = (bids, directoryId) => {
        return directoryId && bids
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/remove-hotels`, {bids}
            )
            : $q.reject({status: 404})
    };

    $repo.addUsers = (users, directoryId, message) => {
        return directoryId && users
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/add-users`,
                { users, message}
            )
            : $q.reject({status: 404})
    };

    $repo.getAddedUsers = (directoryId) => {
        return directoryId
            ? mainServer.get(`hotel-directories/${directoryId}/added-users`)
            : $q.reject({status: 404})
    };

    $repo.getViewUsers = (directoryId, viewId) => {
      return directoryId && viewId
          ? mainServer.get(`hotel-directories/${directoryId}/added-users/${viewId}`)
          : $q.reject({status: 404})
  };

    $repo.updateUser = (directoryId, user) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/user`,
            user
        );
    };

    $repo.assignView = (users, viewId, directoryId) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/assign-user-views`,
            {users, viewId}
        );
    };

    $repo.resendUserLink = (users, directoryId, message) => {
        return mainServer.put(
            `hotel-directories/link/${directoryId}/resend/`,
            {users, message}
        );
    };

    $repo.refreshUserLink = (userId, directoryId, message) => {
        return mainServer.put(
            `hotel-directories/update/${directoryId}/refresh-link/${userId}`,
            {...message}
        );
    };

    $repo.removeUsers = (users, directoryId) => {
        return directoryId && users
            ? mainServer.post(
                `hotel-directories/update/${directoryId}/remove-users`, {users}
            )
            : $q.reject({status: 404})
    };

    $repo.deactivateUserLink = (userId, directoryId) => {
        return directoryId && userId
            ? mainServer.delete(
                `hotel-directories/update/${directoryId}/user/${userId}/break-link`
            )
            : $q.reject({status: 404})
    };

    $repo.saveTravelPolicy = (directoryId, file, progressCallback) => {
        const fd = new FormData();
        fd.append('file', file);
        return directoryId && file
            ? mainServer.sendFile(`hotel-directories/update/${directoryId}/travel-policy`, fd, progressCallback)
            : Promise.reject({status: 404})
    };

    $repo.deleteTravelPolicy = (directoryId) => {
        return directoryId
            ? mainServer.delete(`hotel-directories/update/${directoryId}/delete-travel-policy`)
            : Promise.reject({status: 404})
    };

    $repo.getViewHotels = (directoryId, viewId) => {
        return directoryId && viewId
            ? mainServer.get(`hotel-directory-view/${directoryId}/hotels/${viewId}?editing=true`)
            : $q.reject({status: 404})
    };

    $repo.getTravelDestinations = (directoryId) => {
      return directoryId
        ? mainServer.get(`hotel-directory-view/${directoryId}/travel-destinations`)
        : $q.reject({status: 404})
    };

    $repo.sendLinkToSelf = (viewId, messageRequest) => {
        return messageRequest && viewId
            ? mainServer.put(`hotel-directories/send-link-to-self/${viewId}`, messageRequest)
            : $q.reject({status: 404})
    };


    $repo.getMessagePreview = (viewId, messageRequest) => {
      return messageRequest && viewId
          ? mainServer.put(`hotel-directories/preview-link-to-self/${viewId}`, messageRequest)
          : $q.reject({status: 404})
  };

    $repo.getOrCreateLinkForCurrentUser = viewId => {
        return viewId
            ? mainServer.put(`hotel-directories/get-or-create-view-link/${viewId}`)
            : $q.reject({status: 404})
    };

    $repo.startExploring = (directoryId) => directoryId? mainServer.put(`hotel-directories/${directoryId}/start-exploring-directory`): $q.reject({status: 404});

    $repo.extendTrial = (directoryId) => directoryId? mainServer.put(`hotel-directories/${directoryId}/extend-trial`): $q.reject({status: 404});

    $repo.optInToPay = data => {
      return data
        ? mainServer.put(`hotel-directories/update-opting-for-payment`, data)
        : $q.reject({status: 404})
    };

    $repo.addTutorial = tutorial => {
      return tutorial
        ? mainServer.put(`hotel-directories/update-user-tutorial/${tutorial}`)
        : $q.reject({status: 404})
    };

    $repo.loadRfps = id => id? mainServer.get(`hotel-directories/${id}/rfps`): $q.reject({status: 404});

    $repo.exportHotels = (directoryId, viewId) => directoryId && viewId? mainServer.postAndAutoSaveFile(`hotel-directory/${directoryId}/export/${viewId}`): $q.reject({status: 404});

    $repo.loadLocations = (directoryId) => directoryId
      ? mainServer.get(`hotel-directory/${directoryId}/locations`)
      : $q.reject({status: 404})

    $repo.downloadLocations = (filename, locations = []) => {
      return mainServer.asCsvFileDownload(
        filename,
        ['name', 'address', 'latitude', 'longitude'],
        locations.map(l => ({
          name: l.name,
          address: l.address,
          latitude: l.lat,
          longitude: l.lon,
        }))
      );
    }

    $repo.saveLocations = (directoryId, locations) => directoryId
      ? mainServer.put(`hotel-directory/${directoryId}/locations`, locations)
      : $q.reject({status: 404})

    $repo.getLocationViewNotification = (viewId) => {
      return viewId ? mainServer.get(`hotel-directory-view/${viewId}/locations/notification`) : $q.reject({status: 404})
    }

    $repo.setLocationViewNotification = (viewId, notification) => {
      return viewId ? mainServer.put(`hotel-directory-view/${viewId}/locations/notification`, {notification}) : $q.reject({status: 404})
    }

    $repo.createDirectoryNote = (note, propertyIds) => {
        return propertyIds.length > 0? mainServer.post('hotel-directories/properties/notes', {...note, propertyIds}): $q.reject({status: 404})
    }

    $repo.saveDirectoryNote = (note) => {
        return note.id? mainServer.put(`hotel-directories/properties/notes/${note.id}`, {...note, propertyIds: []}): $q.reject({status: 404})
    }

    $repo.deleteDirectoryNote = (id) => {
        return id? mainServer.delete(`hotel-directories/properties/notes/${id}`): $q.reject({status: 404})
    }

    function getDefaultHotelDirectory(account){
        return {
            accountId: account.id,
            travelPolicy: '',
            defaultUserMessage: `${account.name} invites you to access our new Hotel Directory. The directory provides an up to date listing of all our preferred hotels. We do expect you to use these hotels when ever you make travel arrangements for ${account.name}.
                We appreciate your adherence to these rules and wish you safe and prosperous travels. Feel free to contact me at anytime should you have any questions about ${account.name} hotel program.
            `,
            views: [
                createDefaultView(account, 'Traveler View'),
                createDefaultView(account, 'Travel Agency View', true),
            ]
        }
    }

    function createDefaultView(account, name, isTravelAgency = false){
        const date = new Date();
        return {
            name,
            columns: !isTravelAgency? columns.filter(column => column.default).map(column => column.id): columns.filter(column => column.default).map(column => column.id).concat('sabrecode'),
            header: `${account.name} ${+date.getFullYear()+1} Hotel Directory`,
            subHeader: '',
            showSubHeader: true,
            warning: '',
            showWarning: true,
            background: {
                type: 'IMAGE',
                value: 'https://images.pexels.com/photos/268819/pexels-photo-268819.jpeg?auto=compress&cs=tinysrgb&h=650&w=940'
            },
            hotelTypes: ['L', 'UU', 'U', 'UM', 'M', 'E'],
            rates: ['SS', 'SD', 'US', 'UD', 'DS', 'DD', 'DYN'],
            useLocations: false,
            useLocationsAsDefault: false,
            locationsLabel: 'Locations'
        }
    }
}
