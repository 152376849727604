import { checkAndExecute } from 'root/api/helpers';

export default function MessagingApi(api) {

  api.messaging = {

    getCurrentUserReadMessages: from => api.server.get(`messaging/user-account/read`, {from}),

    getBidMessagingDetails: bidId => checkAndExecute(
      () => bidId,
      () => api.server.get(`messaging/bids/${bidId}`)
    ),

    getBidMessages: (bidId, conversationType, from) => checkAndExecute(
      () => bidId && conversationType,
      () => api.server.get(`messaging/bids/${bidId}/${conversationType}`, {from})
    ),

    sendMessage: (bidId, conversationType, message) => checkAndExecute(
      () => bidId && conversationType && message,
      () => api.server.put(`messaging/bids/${bidId}/${conversationType}`, {message})
    ),

    sendMessages: (bidsIds, conversationType, message) => checkAndExecute(
      () => bidsIds && bidsIds.length && conversationType && message,
      () => api.server.post(`messaging/bids/${conversationType}/messages`, {bidsIds, message})
    )
  }
}
