<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import {notificationUtils, mainAPI} from 'root/angular-injector-provider';
  import TextArea from 'root/components/molecules/inputs/TextArea.labeled.svelte'

  export let hide, bidsIds, side;

  const TYPE_PUBLIC = 'PUBLIC', TYPE_PRIVATE = 'PRIVATE', TYPE_PERSONAL = 'PERSONAL';
  const ICON_CHECKED = 'radio_button_checked', ICON_UNCHECKED = 'radio_button_unchecked';

  const messageTypeOptions = [
    { type: TYPE_PUBLIC, label: 'Messages', description: () => 'Conversation between buyers and suppliers.'},
    { type: TYPE_PRIVATE, label: 'Notes', description: s => `Conversation between ${s === 'BUYER'? 'buyers': 'suppliers'} only.`},
    { type: TYPE_PERSONAL, label: 'Personal Notes', description: () => 'Notes visible only to you.'},
  ];

  let newMessage = '', conversationType = TYPE_PUBLIC;

  function setConversationType(type){
    conversationType = type;
  }

  async function sendMessage(){
    if(newMessage && newMessage.trim()){
      await notificationUtils().onSave(() => mainAPI().messaging.sendMessages(bidsIds, conversationType, newMessage));
      hide();
    }
  }

</script>

<ClosableModal close="{hide}" width="{'600px'}">
  <div slot="heading"><ModalTitle title="Send Messages" /></div>

  <div class="Buckets">
    <div class="BucketsLabel">
      Select message type:
    </div>
    <div class="BucketOptions">
      {#each messageTypeOptions as o}
        <div class="BucketOption"
             role="button"
             data-icon-before="{conversationType === o.type? ICON_CHECKED: ICON_UNCHECKED}"
             on:click={() => setConversationType(o.type)}
        >
          <div>
            {o.label}<br />
            <span class="BucketDescription">{o.description(side)}</span>
          </div>
        </div>
      {/each}
    </div>
  </div>

  <div class="NewMessageForm">
    <div class="NewMessageTextArea">
        <TextArea bind:value="{newMessage}"
                  placeholder="Type a message"
                  maxLength="1000"
                  maxHeight="200px"
        >
          Message:
        </TextArea>
    </div>
    <button class="aBtn asSmall atLight SendMessage" on:click={sendMessage}>Send</button>
  </div>
</ClosableModal>

<style lang="stylus">.Buckets {
  margin-top: -30px;
  padding: 10px 15px;
}
.BucketsLabel {
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  text-align: left;
  margin: 10px 0;
}
.BucketOptions {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.BucketOption {
  border: 0;
  font-size: 12px;
  line-height: 16px;
  color: #90a4ae;
  text-align: left;
  font-weight: bold;
  margin: 0 !important;
  cursor: pointer;
}
.BucketOption:before {
  margin-right: 10px;
}
.BucketOption:hover {
  color: #78909c;
}
.BucketDescription {
  color: #b0bec5;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
}
.NewMessageForm {
  display: flex;
  padding: 20px;
  align-items: flex-end;
}
.NewMessageTextArea {
  flex: 1 1 100%;
  padding-right: 10px;
}
button.SendMessage {
  height: 42px;
}
</style>
