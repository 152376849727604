/**
 * Combines all bid manager stores for easier access
 */
import {default as viewStateStore, initialize as initializeViewStateStore} from 'rfp/bid-manager/main/stores/view-state';
import {default as bidsViewStore} from 'rfp/bid-manager/main/stores/bids-view';
import {default as actionsStore} from 'rfp/bid-manager/main/stores/actions';
import {writable} from 'svelte/store';
import cloneDeep from 'lodash/cloneDeep';

const store = writable({}, () => {
  const vssUnsubscribe = viewStateStore.subscribe($vss => {
    if(!$vss.initialized) return;

    store.update($s => ({
      ...$s,
      $viewState: $vss,
      view: {
        id: $vss.id,
        name: $vss.name,
        side: $vss.side,
        type: $vss.type,
        rfpType: $vss.rfpType,
        rfpId: $vss.rfpId,
        ownerId: $vss.ownerId,
        userAccountType: $vss.userAccountType,

        columns: $vss.columns,
        groupByOptions: $vss.groupByOptions,
        tableGroupedBy: $vss.tableGroupedBy,
        tableSortedBy: $vss.tableSortedBy,
        tableFilteredBy: $vss.tableFilteredBy,
        filterByOptions: $vss.filterByOptions,
      },
    }));
  });

  const bvUnsubscribe = bidsViewStore.subscribe($bvs => {
    if(!$bvs.initialized) return;
    store.update($s => ({
      ...$s,
      $bidsView: $bvs,
      bidsLoaded: $bvs.bidsLoaded,
      bids: [...$bvs.bids],
      negotiatedBid: $s.negotiatedBid ? getBidCopy($bvs, $s.negotiatedBid._id) : undefined
    }));
  });

  const actionsUnsubscribe = actionsStore.subscribe($as => {
    if(!$as.initialized) return;

    store.update($s => ({
      ...$s,
      $actionsRaw: $as,
      selectedBids: $as.selected,
      actions: $as.actions,
    }));
  });

  return () => {
    vssUnsubscribe();
    bvUnsubscribe();
    actionsUnsubscribe();
    store.set({});
  }
});

let scrollListToTop;

export default store;

export function initialize(config){
  return initializeViewStateStore(config)
}

export function openNegotiationsPanel(bidId){
  store.update($s => ({
    ...$s,
    negotiationPanelOpened: true,
    negotiatedBid: getBidCopy($s.$bidsView, bidId)
  }));
}

export function closeNegotiationsPanel(){
  store.update($s => ({
    ...$s,
    negotiationPanelOpened: false,
    negotiatedBid: undefined
  }));
}

export function setNegotiatedBid(bidId){
  store.update($s => ({
    ...$s,
    negotiatedBid: getBidCopy($s.$bidsView, bidId)
  }));
}

export function setScrollToTop(scrollToTopFn){
  scrollListToTop = scrollToTopFn;
}

export function scrollToTop(){
  if(scrollListToTop) scrollListToTop();
}

export function setConversion(rates){
  store.update($s => ({
    ...$s,
    convertCurrency: rates,
  }))
}

function getBidCopy(bidsView, bidId){
  return cloneDeep(bidsView.$bidsRaw.bids.find(b => b._id === bidId));
}

