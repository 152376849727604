/**
 * Stores raw bids from http response
 */


import {default as viewConfigStore} from 'rfp/bid-manager/main/stores/view-config';
import {writable, get} from 'svelte/store';
import {mainAPI} from 'root/angular-injector-provider';
import isObject from 'lodash/isObject';

const store = writable({ bids: [] }, () => {
  return () => {
    store.set({bids: []});
  }
});

export default store;

export function initialize(config){
  const $vcs = get(viewConfigStore),
    query = {
      viewId: $vcs.id,
      fields: buildQueryFields($vcs),
      filter: buildQueryFilter($vcs),
      sort: buildQuerySort($vcs)
    };

  return loadBids(config, query)
    .then(response => {
      if(config.isChainPortal) {
        response = config.formatRows(response);
      }
      store.update($s => ({
        $config: config,
        $query: query,
        ...$s,
        initialized: true,
        lastHttpResponse: response,
        lastHttpResponseTimestamp: new Date().getTime(),
        bids: [...response],
      }));
    });
}

export function updateBids(actionReport){
  const reports = actionReport && actionReport.children;

  if(reports && reports.length) {
    store.update($s => {
      let changes = false
      const updatedBids = [...$s.lastHttpResponse];

      reports.forEach(report => {
        const modifiedBid = report.getData();
        if(modifiedBid){
          const index = updatedBids.findIndex(b => b._id === modifiedBid._id);
          if (index !== -1) {
            changes = true;
            if (['DELETED', 'BUYER_DELETED'].includes(modifiedBid.state.status)) {
              updatedBids.splice(index, 1);
            } else {
              updatedBids[index] = modifiedBid;
            }
          }
        }
      });

      return !changes ? $s : {
        ...$s,
        lastHttpResponse: updatedBids,
        lastHttpResponseTimestamp: new Date().getTime(),
        bids: [...updatedBids],
      };
    })
  }

  return actionReport;
}

function loadBids(config, query){
  if(!config.isChainPortal) {
    return mainAPI().getBidManagerBids(query);
  } else {
    return mainAPI().loadChainPortalBids(config.chainPortalConfig);
  }
}

function buildQueryFields($s) {
  const fields = [];
  addFieldsFromColumns($s.columns);
  if($s.querySort) addFieldsFromSort($s.querySort);
  if($s.queryGroup) addFieldsFromGroup($s.queryGroup);
  return fields;

  function addFieldsFromColumns(columns) {
    for(let i=0, l=columns.length; i<l; i++){
      const columnFields = columns[i].fields,
        fieldKeys = Object.keys(columnFields);

      for(let j=0,jl=fieldKeys.length; j<jl; j++){
        const fieldValue = columnFields[fieldKeys[j]];
        if(isObject(fieldValue)) {
          for(const fKey in fieldValue){
            if(fieldValue.hasOwnProperty(fKey)) addToFields(fieldValue[fKey]);
          }
        } else {
          addToFields(fieldValue);
        }
      }

    }
  }

  function addFieldsFromSort(sortByColumns) {
    for(const sortColumnKey in sortByColumns){
      if(sortByColumns.hasOwnProperty(sortColumnKey)){
        const sortable = sortByColumns[sortColumnKey].$sortable;
        if(sortable) addFieldsNeededToSortSortableColumns(sortable);
      }
    }
  }

  function addFieldsFromGroup(groupByColumn) {
    if(groupByColumn.$groupable) addFieldsNeededToGroupGroupableColumns(groupByColumn.$groupable);
  }

  function addFieldsNeededToSortSortableColumns(sortable) {
    for(let i=0, il=sortable.length; i<il; i++){
      addToFields(sortable[i]);
    }
  }

  function addFieldsNeededToGroupGroupableColumns(groupable) {
    addToFields(groupable.by);
    if(groupable.text) addToFields(groupable.text);
  }

  function addToFields(field) {
    if (fields.indexOf(field) === -1) {
      fields.push(field);
    }
  }
}

function buildQueryFilter($s){
  const filters = {}, viewFilter = $s.queryFilter;
  for(const filterKey in viewFilter){
    if(viewFilter.hasOwnProperty(filterKey)){
      const filterData = viewFilter[filterKey];
      if(filterData.by){
        filters[filterData.by.$filter.by] = filterData.value
      }
    }
  }
  return filters;
}

function buildQuerySort($s){ // todo: zasto je Object ????
  const sort = {}, viewSort = $s.querySort;
  if($s.queryGroup) addGroupToSort($s.queryGroup);

  for(const sortKey in viewSort){
    if(viewSort.hasOwnProperty(sortKey)){
      const sortData = viewSort[sortKey],
        sortable = sortData.by.$sortable;
      if(sortable) {
        for (let i = 0, il = sortable.length; i < il; i++) {
          const field = sortable[i];
          sort[field] = sortData.direction;
        }
      }
    }
  }
  return sort;

  function addGroupToSort(viewGroup){
    sort[viewGroup.$groupable.by] = 1;
  }
}
