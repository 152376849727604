/**
 * Created by DejanK on 1/11/2017.
 */
import { noop } from 'lodash';
import './edit-destination.scss';
import HTML from './edit-destination.html';
import { getAddressType, TYPE_OFFICE } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { getValidationRules } from 'root/rfp-hotel/main/rfp/destinations/travel-destination-validation';
import currencies from 'vRoot/rfp-hotel/bid/rate-quick/inputs/currencies';

const NAME = 'addTravelDestination'

export { NAME, HTML, RfpAddDestinationController as Controller}

RfpAddDestinationController.$inject = ['RfpHotelRfpDestinationsRepository', 'RfpId', 'rbDialog', 'NotificationUtils'];
function RfpAddDestinationController(repository, RfpId, dialog, notificationUtils){
  const vm = this
  let form;

  vm.destinationRules = getValidationRules()
  vm.pageTitle = 'Create Travel Destination'
  vm.destination = {
    rfpId: RfpId,
    type: TYPE_OFFICE,
    currency: 'USD',
    caps: [],
  }
  vm.addRate = addRate;
  vm.removeRate = removeRate;
  vm.minDate = minDate;
  vm.showCaps = false;
  vm.onFormLoaded = onFormLoaded;
  vm.tabs = [
    {
      id: 'address',
      label: () => vm.destination.type === 'AIRPORT'? 'Destination Airport': 'Destination Address',
      show: () => true,
      valid() {
        const addressForm = form['$ctrl.addressForm'];
        return addressForm.$valid;
      },
    },
    {
      id: 'spend',
      label: () => 'Room Night Volumes',
      show: () => true,
      valid() {
        return form.estSpend.$valid && form.estRoomNights.$valid;
      },
    },
    {
      id: 'caps',
      label: () => 'Destination Rate Caps',
      show: () => vm.showCaps,
      valid() {
        const addressForm = form['$ctrl.addressForm'];
        const valid = addressForm.$valid && form.estSpend.$valid && form.estRoomNights.$valid && form.name.$valid;
        return form.$valid || !valid;
      }
    }
  ];

  vm.toggleCap = () => {
    vm.showCaps = !vm.showCaps;
    if(vm.activeTab === 'caps') {
      vm.activeTab = 'spend';
    }
  }

  onTypeChange()

  vm.cancel = dialog.cancel
  vm.onSubmit = onSubmit
  vm.onTypeChange = onTypeChange
  vm.activeTab = 'address';
  vm.currencies = currencies;

  function onSubmit(isFormValid){
    vm.showForm = true;
    if(!vm.showCaps) {
      vm.destination.caps = [];
    }

    if(isFormValid){
      vm.saving = 'saving'
      notificationUtils.onSave(() => repository.createRfpTravelDestination(vm.destination, RfpId, vm.destination.type) )
        .then(dialog.hide, noop)
        .finally(() => { vm.saving = null })
    }
  }

  function onTypeChange(){
    vm.addressType = getAddressType(vm.destination.type)
    delete vm.destination.location
  }

  function addRate() {
    vm.destination.caps.push({
      startDate: minDate(vm.destination.caps.length),
      endDate: vm.rfp.specifications.programEndDate,
      rate: 0,
    });
  }

  function removeRate(index) {
    vm.destination.caps.splice(index, 1);
  }

  function minDate(index) {
    return index > 0? vm.destination.caps[index - 1].endDate: vm.rfp.specifications.programStartDate;
  }

  function onFormLoaded( f ) {
    form = f;
  }
}
