import HTML from './rb-ng-select-airport.html'
import { get } from 'lodash'

export default {
  template: HTML,
  controller: Controller,
  bindings: {
    model: '=',
    type: '<',
    showForm: '<',
    required: '<'
  }
}

Controller.$inject = ['$scope'];
function Controller($scope) {
  const vm = this

  vm.$onChanges = $onChanges
  vm.onAddManually = onAddManually
  vm.onAutocompleteSelected = onAutocompleteSelected

  function $onChanges(changes){
    changes.required && updateRequired(changes.required.currentValue)
    changes.showForm && changes.showForm.currentValue && onAddManually()
  }

  function updateRequired(val) {
    vm.required = val
  }

  function onAutocompleteSelected($event) {
    vm.model = $event.airport.location;
    vm.selected = $event.airport;
  }

  function onAddManually() {
    const c = get(vm, 'model.address.country');
    if(!c){ vm.model = { address: { country: 'US' } } }
    $scope.$timeout(() => { vm.addressForm.$rbTouchAllFields() })
  }
}
