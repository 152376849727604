<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="$style.root">
    <div
      :class="[$style.note, 'note']"
      :style="{ backgroundColor: note.color }"
    />

    <div :class="$style.text">
      <p :class="$style.title">{{ note.title }}</p>
      <div v-html="note.note.replace(/\n/g, '<br>')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotelDirectoryHotelNote',
  props: {
    note: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="stylus" module>
.note {
  width 11px;
  height 11px;
  border-radius 2px;
  border 1px solid #546f7a4f;
  transition transform .2s ease-in-out;

  &:hover {
    transform scale(1.1);
  }
}

.root {
  position relative

  &:hover .text {
    display block;
  }
}

.title {
  font-size 14px;
  margin-bottom 5px;
  color rgb(84 111 122);
  font-weight bold;
}

.text {
  position absolute;
  bottom calc(100% + 5px);
  background #fff;
  padding 10px;
  border-radius 2px;
  box-shadow 0 0 5px rgba(0, 0, 0, .2);
  color rgb(84 111 122);
  left 0;
  font-size 16px;
  display none;
  width 400px;
  max-height 400px;
  overflow auto;
  white-space pre-wrap;
}
</style>
