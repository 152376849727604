<script>
  import 'root/ui-ng/notifications/states/notices.scss';
  import {$state as state} from 'root/angular-injector-provider';
  import {PAGE_HOME} from 'root/states';

  export let
    title = 'Awww',
    subtitle = 'Page not found',
    header = 'Sorry',
    text = 'The page you requested wasn\'t found.',
    action = {label: 'Homepage', execute: () => { state().go(PAGE_HOME); }};

</script>

<div class="Container">
  <div class="rb-notice error">
    <div>
      <h3>{title}</h3>
      <h1>{subtitle}</h1>
    </div>

    <div class="margin-vertical-l">
      <div><i class="material-icons" style="font-size: 110px;">error_outline</i></div>
    </div>

    <div>
      <h3>{header}</h3>
      <p>{text}</p>
    </div>

    <div class="margin-top">
      {#if action}
        <button class="main-action"
                on:click="{action.execute}">
          {action.label}
        </button>
      {/if}
    </div>
  </div>
</div>

<style lang="stylus">.Container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
