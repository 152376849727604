/**
 * Stores the view state - these can be modified by the front end
 */

import {
  default as viewConfigStore,
  initialize as initializeViewConfigStore
} from 'rfp/bid-manager/main/stores/view-config';
import {default as bidsRawStore, initialize as initializeBidsRawStore} from 'rfp/bid-manager/main/stores/bids-raw';

import {writable} from 'svelte/store';
import {get, orderBy} from 'lodash';


const store = writable({}, () => {
  const vcsUnsubscribe = viewConfigStore.subscribe($vcs => {
    if(!$vcs.initialized) return;

    store.update($s => ({
      ...$s,
      $viewConfig: $vcs,
      id: $vcs.id,
      name: $vcs.name,
      side: $vcs.side,
      type: $vcs.type,
      rfpType: $vcs.rfpType,
      rfpId: $vcs.rfpId,
      ownerId: $vcs.ownerId,
      userAccountType: $vcs.userAccountType,

      columns: $vcs.columns,
      groupByOptions: $vcs.groups,
      quickFilterQuery: ''
    }));
  });

  const brsUnsubscribe = bidsRawStore.subscribe($brs => {
    if(!$brs.initialized) return;
    store.update($s => {
      addQuickSearchString($brs.bids, $s.$viewConfig.quickSearchConfig);

      return {
        ...$s,
        $bidsRaw: $brs,
        filterByOptions: getFilterByOptions($brs.bids, $s.tableGroupedBy || $s.$viewConfig.queryGroup)
      }
    });
  });

  return () => {
    vcsUnsubscribe();
    brsUnsubscribe();
    store.set({});
  }
});

export default store;

export function initialize(config) {
  return initializeViewConfigStore(config)
    .then(() => initializeBidsRawStore(config))
    .then(loadStateFromViewConfig);
}

export function setGroupBy(group){
  store.update($s => ({
    ...$s,
    tableGroupedBy: group,
    tableFilteredBy: undefined,
    filterByOptions: getFilterByOptions($s.$bidsRaw.bids, group)
  }))
}

export function setFilterBy(filter){
  store.update($s => ({
    ...$s,
    tableFilteredBy: filter
  }));
}

export function setSortBy(sortBy){
  if(!sortBy) return;
  store.update($s => {
    const isTheNewSortByTheSameColumn = get($s.tableSortedBy, 'by.id') === sortBy.id;
    return {
      ...$s,
      tableSortedBy: isTheNewSortByTheSameColumn
        ? { ...$s.tableSortedBy, direction: $s.tableSortedBy.direction * -1}
        : { by: sortBy, direction: 1}
    };
  });
}

export function setQuickFilterQuery(query = ''){
  store.update($s => {
    return {
      ...$s,
      quickFilterQuery: query
    };
  });
}

function loadStateFromViewConfig(){
  store.update($s => ({
    ...$s,
    initialized: true,
    tableGroupedBy: $s.$viewConfig.queryGroup,
    tableSortedBy: $s.$viewConfig.querySort ? $s.$viewConfig.querySort[Object.keys($s.$viewConfig.querySort)[0]] : undefined,
    filterByOptions: getFilterByOptions($s.$bidsRaw.bids, $s.$viewConfig.queryGroup)
  }));
}

function getFilterByOptions(bids = [], groupBy = {}){
  const groupByFilter = groupBy.$filter;
  if(!groupByFilter) return undefined;

  const filter = bids.reduce( (acc, bid) => {
    const value = get(bid, groupByFilter.by);
    if(value !== undefined) acc[value] = { value, label: get(bid, groupByFilter.values) };
    return acc;
  }, {});

  let filtersList = Object.values(filter);
  filtersList = orderBy(filtersList, ['label']);
  filtersList.unshift({label: 'None'});
  return filtersList;
}

function addQuickSearchString(bids = [], config){
  if(!config) return;
  bids.forEach(bid => {
    bid.$$quickSearchString = config.mapFn(bid).trim().toLowerCase();
  });
}
