export default {
  'bid': {
    '$title': 'Bid Columns',
    '$description': 'Common RFP Bid Columns',
    '$group': true,
    'rfp': {
      'id': 'bid.rfp',
      'isCondensed': true,
      'type': 'blockRFP',
      'fields': {
        'id': 'rfp._id',
        'name': 'rfp.specifications.name',
        'type': 'rfp.specifications.type',
        'dueDate': 'rfp.specifications.dueDate'
      },
      '$sortable': ['rfp.specifications.name', 'rfp._id'],
      '$groupable': {'by': 'rfp._id', 'text': 'rfp.specifications.name'},
      '$filter': {
        'by': 'rfp._id',
        'type': 'loadList',
        'values': 'rfp.specifications.name'
      },
      'label': 'RFP',
      'description': 'RFP Name, Due Date and Type',
      'tooltip': '<div style="text-align: left"><b>RFP information</b><div>RFP Name</div><div>RFP Due Date</div></div>',
      'abbreviation': 'RFP',
      'class': 'xl',
      'export': [
        {
          'label': 'RFP Name',
          'type': 'String',
          'field': 'rfp.specifications.name'
        },
        {
          'label': 'RFP Due Date',
          'type': 'Date',
          'field': 'rfp.specifications.dueDate'
        }
      ]
    },

    'rfpName': {
      'id': 'bid.rfpName',
      'fields': {
        'id': 'rfp._id',
        'text': 'rfp.specifications.name'
      },
      '$sortable': ['rfp.specifications.name', 'rfp._id'],
      '$groupable': {'by': 'rfp._id', 'text': 'rfp.specifications.name'},
      '$filter': {
        'by': 'rfp._id',
        'type': 'loadList',
        'values': 'rfp.specifications.name'
      },
      'label': 'RFP',
      'description': 'RFP Name',
      'tooltip': '<div style="text-align: left">RFP Name</div>',
      'abbreviation': 'RFP',
      'class': 'xl',
      'export': [
        {
          'label': 'RFP Name',
          'type': 'String',
          'field': 'rfp.specifications.name'
        }
      ]
    },

    'hotelRfpType': {
      'id': 'bid.hotelRfpType',
      'fields': {
        'id': 'hotelRfpType',
        'text': 'hotelRfpType'
      },
      '$sortable': ['hotelRfpType', 'hotelRfpType'],
      '$groupable': {'by': 'hotelRfpType', 'text': 'hotelRfpType'},
      '$filter': {
        'by': 'hotelRfpType',
        'type': 'loadList',
        'values': 'hotelRfpType'
      },
      'label': 'Bid Type',
      'description': 'RFP Name',
      'tooltip': '<div style="text-align: left">Bid Type</div>',
      'abbreviation': 'Bid Type',
      'class': 's',
      'export': [
        {
          'label': 'RFP Type',
          'type': 'String',
          'field': 'hotelRfpType'
        }
      ]
    },

    'rfpProgramYear': {
      'id': 'bid.rfpProgramYear',
      'fields': {
        'id': 'rfp.specifications.programYear',
        'text': 'rfp.specifications.programYear'
      },
      '$groupable': {'by': 'rfp.specifications.programYear', 'text': 'rfp.specifications.programYear'},
      '$filter': {
        'by': 'rfp.specifications.programYear',
        'type': 'loadList',
        'values': 'rfp.specifications.programYear'
      },
      'label': 'Program Year',
      'description': 'Program Year',
      'tooltip': '<div style="text-align: left">Program Year</div>',
      'abbreviation': 'PY',
      'class': 'xl',
      'type': 'hidden',
      'export': [
        {
          'label': 'Program Year',
          'type': 'String',
          'field': 'rfp.specifications.programYear'
        }
      ]
    },

    'rfpDueDate': {
      'id': 'bid.rfpDueDate',
      'fields': {
        'id': 'rfp.specifications.dueDate',
        'text': 'rfp.specifications.dueDate'
      },
      'type': 'date',
      '$sortable': ['rfp.specifications.dueDate', 'rfp.specifications.dueDate'],
      'label': 'Due Date',
      'description': 'Due Date',
      'tooltip': '<div style="text-align: left">Due Date</div>',
      'abbreviation': 'Due Date',
      'class': 's',
      'export': [
        {
          'label': 'Due Date',
          'type': 'String',
          'field': 'rfp.specifications.dueDate'
        }
      ]
    },

    'status':{
      'id': 'bid.status',
      'isCondensed': true,
      'type': 'blockStatus',
      'fields': {
        'status': 'state.status',
        'at': 'state.at',
        'label': 'state.label'
      },
      '$groupable': {'by': 'state.label'},
      '$sortable': ['state.at'],
      '$filter': {
        'by': 'state.label',
        'type': 'loadList',
        'values': 'state.label'
      },
      'label': 'Status',
      'Description': 'RFP Status with last status change date and by whom.',
      'tooltip': '<div style="text-align: left"><b>RFP Status</b><div>RFP Status</div><div>Status Time</div><div>User Status Changed By</div></div>',
      'abbreviation': 'ST',
      'class': 'm',
      'export': [
        {
          'label': 'Status',
          'type': 'String',
          'field': 'state.label'
        },
        {
          'label': 'Status Message',
          'type': 'String',
          'field': 'state.properties.note'
        },
        {
          'label': 'Status at',
          'type': 'DateTime',
          'field': 'state.at'
        },
        {
          'label': 'Manual Response',
          'type': 'String',
          'field': 'manualResponse'
        },
      ]
    },

    'statusValue':{
      'id': 'bid.statusValue',
      'fields': {
        'text': 'state.label'
      },
      '$groupable': {'by': 'state.label'},
      '$sortable': ['state.label'],
      '$filter': {
        'by': 'state.label',
        'type': 'loadList',
        'values': 'state.label'
      },
      'label': 'Status',
      'Description': 'RFP Bid Status',
      'tooltip': '<div style="text-align: left">RFP Bid Status</div>',
      'abbreviation': 'ST',
      'class': 'm',
      'export': [
        {
          'label': 'Status',
          'type': 'String',
          'field': 'state.label'
        }
      ]
    },

    'statusAt':{
      'id': 'bid.statusAt',
      'type': 'shortDateTime',
      'fields': {
        'text': 'c.at'
      },
      '$sortable': ['state.at'],
      'label': 'Status At',
      'Description': 'RFP Status date.',
      'tooltip': '<div style="text-align: left">RFP Bid Status date</div>',
      'abbreviation': 'ST AT',
      'class': 's',
      'export': [
        {
          'label': 'Status at',
          'type': 'DateTime',
          'field': 'state.at'
        },
      ]
    },

    'createdBy':{
      'id': 'bid.createdBy',
      'isCondensed': true,
      'type': 'blockStatus',
      'fields': {
        'at': 'created.at',
        'by': 'created.by.name',
        'id': 'created.by._id'
      },
      '$groupable': {'by': 'created.by._id'},
      '$sortable': ['created.at'],
      '$filter': {
        'by': 'created.by._id',
        'type': 'loadList',
        'values': 'created.by.name'
      },
      'label': 'Created By',
      'Description': 'RFP creation date and author.',
      'tooltip': '<div style="text-align: left"><b>RFP Creation Details</b><div>Created at</div><div>Created by</div></div>',
      'abbreviation': 'CR',
      'class': 'm',
      'export': [
        {
          'label': 'Created by',
          'type': 'String',
          'field': 'created.by.name'
        },
        {
          'label': 'Created at',
          'type': 'DateTime',
          'field': 'created.at'
        }
      ]
    },

    'email':{
      'id': 'bid.email',
      'type': 'email',
      'fields': {
        'text': 'email.status',
        'id': 'email._id'
      },
      'label': 'Emails',
      'Description': 'Last Sent Email Status',
      'tooltip': '<div style="text-align: left"><b>Last Sent Email Status</b><div>',
      'abbreviation': 'Email',
      'class': 'xxs allow-overflow',
    },


    'rfpType':{
      'id': 'bid.rfpType',
      'fields': {
        'text': 'rfp.specifications.type'
      },
      '$filter': {
        'by': 'rfp.specifications.type',
        'type': 'loadList'
      },
      'label': 'RFP Type',
      'Description': 'RFP type.',
      'tooltip': '<div style="text-align: left"><b>RFP Type</b><div>',
      'abbreviation': 'RT',
      'class': 's',
      'export': [
        {
          'label': 'RFP Type',
          'type': 'String',
          'field': 'rfp.specifications.type'
        }
      ]
    },

    'issuer': {
      '$title': 'Buyer data',
      '$group': true,

      'companyName': {
        'id': 'bid.issuer.companyName',
        // "type": "sref",
        'sref': 'profile.public.company',
        'params': {
          'companyId': 'companyId'
        },
        'fields': {
          'text': 'buyer.company.name',
          'companyId': 'buyer.company.entityId'
        },
        '$sortable': [
          'buyer.company.name',
          'buyer.company.entityId'
        ],
        '$groupable': {
          'by': 'buyer.company.entityId',
          'text': 'buyer.company.name'
        },
        '$filter': {
          'by': 'buyer.company.entityId',
          'type': 'loadList',
          'values': 'buyer.company.name'
        },
        '$quickSearch': {
          label: 'RFP Issuer',
          mapFn: bid => bid.buyer.company.name
        },
        'label': 'RFP Issuer',
        'description': 'RFP Issuer Name with link to details',
        'tooltip': '<div style="text-align: left">RFP Issuer</div>',
        'abbreviation': 'RI',
        'class': 'm accent',
        'export': [
          {
            'label': 'RFP Issuer',
            'type': 'String',
            'field': 'buyer.company.name'
          }
        ]
      },

      'contactName': {
        'id': 'bid.issuer.contactName',
        'type': 'buyerContact',
        'fields': {
          'contactName': 'buyer.contact.fullName',
          'contactId': 'buyer.contact.id'
        },
        '$sortable': [
          'buyer.contact.fullName',
          'buyer.contact.id'
        ],
        '$groupable': {
          'by': 'buyer.contact.id',
          'text': 'buyer.contact.fullName'
        },
        '$filter': {
          'by': 'buyer.contact.id',
          'type': 'loadList',
          'values': 'buyer.contact.fullName'
        },
        '$quickSearch': {
          label: 'Primary',
          mapFn: bid => bid.buyer.contact.fullName
        },
        'label': 'Primary',
        'description': 'Primary',
        'tooltip': '<div style="text-align: left">Primary</div>',
        'abbreviation': 'PM',
        'class': 'm',
        'export': [
          {
            'label': 'Primary',
            'type': 'String',
            'field': 'buyer.contact.fullName'
          }
        ]
      }
    },

    'currency': {
      'id': 'bid.currency',
      'type': 'currency',
      'fields': {
        'text': 'analytics.currency'
      },
      'label': 'Currency',
      'Description': 'Currency',
      'tooltip': '<div style="text-align: left"><b>currency</b><div>',
      'abbreviation': 'CUR',
      '$sortable': [ 'analytics.currency' ],
      '$groupable': {
        'by': 'analytics.currency',
        'text': 'analytics.currency'
      },
      '$filter': {
        'by': 'analytics.currency',
        'type': 'loadList',
        'values': 'analytics.currency'
      },
      'class': 'xxs',
      'export': [
        {
          'label': 'Currency',
          'type': 'String',
          'field': 'analytics.currency'
        }
      ]
    }
  },

  'hotelRfpBid': {
    '$title': 'Hotel RFP Bid Columns',
    '$description': 'Columns specific to Hotel RFP Bid',
    '$group': true,

    'td': {
      '$title': 'Hotel RFP - Travel Destination Columns',
      '$group': true,

      'info':{
        'id': 'hotelRfpBid.td.info',
        'isCondensed': true,
        'type': 'travelDestinationBlock',
        'label': 'Travel Destination',
        'description': 'Travel Destination with Full Address, Estimated Spend and Estimated Room Nights',
        'tooltip': '<div style="text-align: left"><b>Subject - Travel Destination</b><div>Travel Destination Name</div><div>Travel Destination Address</div><div>Estimated Room Nights | Estimated Spend</div></div>',
        'abbreviation': 'TD',
        'fields': {
          'id': 'subject._id',
          'name': 'subject.name',
          'fullAddress': 'subject.location.fullAddress',
          'estimatedRoomNights': 'subject.estRoomNights',
          'estimatedSpend': 'subject.estSpend'
        },
        '$sortable': ['subject.name', 'subject._id'],
        '$groupable': {'by': 'subject._id', 'text': 'subject.name'},
        '$filter': {
          'by': 'subject._id',
          'type': 'loadList',
          'values': 'subject.name'
        },
        'class': 'xl',
        'export': [
          {
            'label': 'Travel Destination Name',
            'type': 'String',
            'field': 'subject.name'
          },
          {
            'label': 'Travel Destination Address',
            'type': 'String',
            'field': 'subject.location.fullAddress'
          },
          {
            'label': 'TD Estimated Room Nights',
            'type': 'Integer',
            'field': 'subject.estRoomNights'
          },
          {
            'label': 'TD Estimated Spend',
            'type': 'Amount',
            'field': 'subject.estSpend'
          }
        ]
      },

      'name':{
        'id': 'hotelRfpBid.td.name',
        'type': 'travelDestinationAndCityCaps',
        'label': 'Travel Destination',
        'description': 'Travel Destination Name',
        'tooltip': '<div style="text-align: left">Travel Destination Name</div>',
        'abbreviation': 'TD',
        'fields': {
          'id': 'subject._id',
          'text': 'subject.name'
        },
        '$sortable': ['subject.name', ''],
        '$groupable': {'by': 'subject._id', 'text': 'subject.name'},
        '$filter': {
          'by': 'subject._id',
          'type': 'loadList',
          'values': 'subject.name'
        },
        'class': 'm',
        'export': [
          {
            'label': 'Travel Destination Name',
            'type': 'String',
            'field': 'subject.name'
          }
        ]
      },

      'nameSimple':{
        'id': 'hotelRfpBid.td.nameSimple',
        'label': 'Travel Destination',
        'description': 'Travel Destination Name',
        'tooltip': '<div style="text-align: left">Travel Destination Name</div>',
        'abbreviation': 'TD',
        'fields': {
          'id': 'subject._id',
          'text': 'subject.name'
        },
        '$sortable': ['subject.name', ''],
        '$groupable': {'by': 'subject._id', 'text': 'subject.name'},
        '$filter': {
          'by': 'subject._id',
          'type': 'loadList',
          'values': 'subject.name'
        },
        'class': 'm',
        'export': [
          {
            'label': 'Travel Destination Name',
            'type': 'String',
            'field': 'subject.name'
          }
        ]
      },

      'address':{
        'id': 'hotelRfpBid.td.address',
        'label': 'Office Location',
        'description': 'Office Location',
        'tooltip': '<div style="text-align: left">Travel Destination Office Location</div>',
        'abbreviation': 'OL',
        'fields': {
          'text': 'subject.location.fullAddress'
        },
        '$sortable': ['subject.location.fullAddress', 'subject._id'],
        'class': 'm',
        'export': [
          {
            'label': 'Travel Destination Address',
            'type': 'String',
            'field': 'subject.location.fullAddress'
          }
        ]
      },

      'rnv':{
        'id': 'hotelRfpBid.td.rnv',
        'label': 'RNV',
        'description': 'Office Room Night Volume',
        'tooltip': '<div style="text-align: left">Travel Destination <b>R</b>oom <b>N</b>ight <b>V</b>olume</div>',
        'abbreviation': 'RNV',
        'fields': {
          'text': 'subject.estRoomNights'
        },
        '$sortable': ['subject.estRoomNights', 'subject.estRoomNights'],
        'class': 'xs',
        'export': [
          {
            'label': 'Travel Destination Room Night Volume',
            'type': 'String',
            'field': 'subject.estRoomNights'
          }
        ]
      }
    },

    'hotel': {
      '$title': 'Hotel RFP - Hotel Related Columns',
      '$group': true,

      'info': { // chain portal only
        'id': 'hotelRfpBid.hotel.info',
        'isCondensed': true,
        'type': 'blockHotel',
        'titleUrl': 'testUrl',
        'contactUrl': 'testUrl',
        'fields': {
          'hotelName': 'supplier.company.name',
          'hotelId': 'supplier.company.entityId',
          'address': 'supplier.company.location.fullAddress',
          'chain': 'supplier.company.chain.name',
          'contactId': 'supplier.contact.id'
        },
        '$sortable': [
          'supplier.company.name',
          'supplier.company.entityId'
        ],
        '$groupable': {
          'by': 'supplier.company.entityId',
          'text': 'supplier.company.name'
        },
        '$filter': {
          'by': 'supplier.company.entityId',
          'type': 'loadList',
          'values': 'supplier.company.name'
        },
        '$quickSearch': {
          label: 'Hotel Name or Address',
          mapFn: bid => `${bid.supplier.company.name} ${bid.supplier.company.location.fullAddress}`
        },
        'label': 'Hotel',
        'description': 'Hotel Name with link to details, Hotel Full Address and Contact Name with link to details.',
        'tooltip': '<div style="text-align: left"><b>Hotel</b><div>Hotel Name</div><div>Hotel Full Address</div><div>Hotel Contact</div></div>',
        'abbreviation': 'HT',
        'class': 'xxl',
        'export': [
          {
            'label': 'Hotel Name',
            'type': 'String',
            'field': 'supplier.company.name'
          },
          {
            'label': 'Hotel Address',
            'type': 'String',
            'field': 'supplier.company.location.fullAddress'
          }
        ]
      },

      'name': {
        'id': 'hotelRfpBid.hotel.name',
        'type': 'hotel',
        'fields': {
          'text': 'supplier.company.name',
          'hotelId': 'supplier.company.entityId'
        },
        '$sortable': [
          'supplier.company.name',
          'supplier.company.entityId'
        ],
        '$groupable': {
          'by': 'supplier.company.entityId',
          'text': 'supplier.company.name'
        },
        '$filter': {
          'by': 'supplier.company.entityId',
          'type': 'loadList',
          'values': 'supplier.company.name'
        },
        '$quickSearch': {
          label: 'Hotel Name',
          mapFn: bid => bid.supplier.company.name
        },
        'label': 'Hotel',
        'description': 'Hotel Name with link to details',
        'tooltip': '<div style="text-align: left">Hotel Name</div>',
        'abbreviation': 'HT',
        'class': 'l accent',
        'export': [
          {
            'label': 'Hotel Name',
            'type': 'String',
            'field': 'supplier.company.name'
          }
        ]
      },

      'address': {
        'id': 'hotelRfpBid.hotel.address',
        'type': 'String',
        'fields': {
          'text': 'supplier.company.location.fullAddress',
          'hotelId': 'supplier.company.entityId'
        },
        '$sortable': [
          'supplier.company.location.fullAddress',
          'supplier.company.entityId'
        ],
        '$filter': {
          'by': 'supplier.company.entityId',
          'type': 'loadList',
          'values': 'supplier.company.location.fullAddress'
        },
        'label': 'Address',
        'description': 'Hotel Address',
        'tooltip': '<div style="text-align: left">Hotel Address</div>',
        'abbreviation': 'HTA',
        'class': 'xs',
        'export': [
          {
            'label': 'Hotel Address',
            'type': 'String',
            'field': 'supplier.company.location.fullAddress'
          }
        ]
      },

      'city': {
        'id': 'hotelRfpBid.hotel.city',
        'type': 'hidden',
        'fields': { 'text': 'analytics.address.city' },
        '$groupable': {
          'by': 'analytics.address.city',
          'text': 'analytics.address.city'
        },
        '$filter': {
          'by': 'analytics.address.city',
          'type': 'loadList',
          'values': 'analytics.address.city'
        },
        'label': 'Hotel City',
        'description': 'Hotel City',
        'tooltip': '<div style="text-align: left">Hotel City</div>',
        'abbreviation': 'HC',
        'class': 'xs'
      },

      'state_region': {
        'id': 'hotelRfpBid.hotel.state_region',
        'type': 'hidden',
        'fields': { 'text': 'analytics.address.stateRegion' },
        '$groupable': {
          'by': 'analytics.address.stateRegion',
          'text': 'analytics.address.stateRegion'
        },
        '$filter': {
          'by': 'analytics.address.stateRegion',
          'type': 'loadList',
          'values': 'analytics.address.stateRegion'
        },
        'label': 'Hotel State/Region',
        'description': 'Hotel State/Region',
        'tooltip': '<div style="text-align: left">Hotel State or Region</div>',
        'abbreviation': 'HSR',
        'class': 'xs'
      },

      'country': {
        'id': 'hotelRfpBid.hotel.country',
        'type': 'hidden',
        'fields': { 'text': 'analytics.address.country' },
        '$groupable': {
          'by': 'analytics.address.country',
          'text': 'analytics.address.country'
        },
        '$filter': {
          'by': 'analytics.address.country',
          'type': 'loadList',
          'values': 'analytics.address.country'
        },
        'label': 'Hotel Country',
        'description': 'Hotel Country',
        'tooltip': '<div style="text-align: left">Hotel Country</div>',
        'abbreviation': 'HCO',
        'class': 'xs'
      },

      'brandName': {
        'id': 'hotelRfpBid.hotel.brandName',
        'type': 'competitiveHidden',
        'fields': {
          'tooltip': 'supplier.company.chain.name',
          'text': 'supplier.company.chain.code'
        },
        '$sortable': [
          'supplier.company.chain.name',
          'supplier.company.chain.code'
        ],
        '$groupable': {
          'by': 'supplier.company.chain.code',
          'text': 'supplier.company.chain.name'
        },
        '$filter': {
          'by': 'supplier.company.chain.code',
          'type': 'loadList',
          'values': 'supplier.company.chain.name'
        },
        'label': 'Hotel Chain Brand',
        'description': 'Hotel Chain Brand',
        'tooltip': '<div style="text-align: left">Hotel Chain Brand</div>',
        'abbreviation': 'BR',
        'class': 'xxs',
        'export': [
          {
            'label': 'Brand Code',
            'type': 'String',
            'field': 'supplier.company.chain.code'
          },
          {
            'label': 'Brand Name',
            'type': 'String',
            'field': 'supplier.company.chain.name'
          }
        ]
      },

      'chainName': {
        'id': 'hotelRfpBid.hotel.chainName',
        'type': 'competitiveHidden',
        'fields': {
          'tooltip': 'supplier.company.chain.master.name',
          'text': 'supplier.company.chain.master.code'
        },
        '$sortable': [
          'supplier.company.chain.master.name',
          'supplier.company.chain.master.code'
        ],
        '$groupable': {
          'by': 'supplier.company.chain.master.code',
          'text': 'supplier.company.chain.master.name'
        },
        '$filter': {
          'by': 'supplier.company.chain.master.code',
          'type': 'loadList',
          'values': 'supplier.company.chain.master.name'
        },
        'label': 'Hotel Chain',
        'description': 'Hotel Chain',
        'tooltip': '<div style="text-align: left">Hotel Chain</div>',
        'abbreviation': 'CH',
        'class': 'xxs',
        'export': [
          {
            'label': 'Chain Code',
            'type': 'String',
            'field': 'supplier.company.chain.master.code'
          },
          {
            'label': 'Chain Name',
            'type': 'String',
            'field': 'supplier.company.chain.master.name'
          }
        ]
      },

      'contact': {
        'id': 'hotelRfpBid.hotel.contact',
        'isCondensed': true,
        'type': 'blockContact',
        'fields': {
          'contactName': 'supplier.contact.fullName',
          'contactId': 'supplier.contact.id',
          'jobTitle': 'supplier.contact.jobTitle',
          'phone': 'supplier.contact.phone',
          'emailAddress': 'supplier.contact.emailAddress',
          'picture': 'supplier.contact.profilePicture',
          'isUser': 'supplier.contact.isUser',
          'contactCompanyName': 'supplier.contact.company.name',
          'contactCompanyId': 'supplier.contact.company.accountId',
          'contactCompanyType': 'supplier.contact.company.type'
        },
        '$sortable': [
          'supplier.contact.fullName',
          'supplier.contact.id'
        ],
        '$groupable': {
          'by': 'supplier.contact.id',
          'text': 'supplier.contact.fullName'
        },
        '$filter': {
          'by': 'supplier.contact.id',
          'type': 'loadList',
          'values': 'supplier.contact.fullName'
        },
        '$quickSearch': {
          label: 'Sales Contact Name or Email Address',
          mapFn: bid => {
            if(!bid.supplier.contact) return '';
            return `${bid.supplier.contact.fullName} ${bid.supplier.contact.emailAddress}`;
          }
        },
        'label': 'Hotel Sales Contact',
        'description': 'Hotel Contact Name with link to details, Job Title and Contact Company',
        'tooltip': '<div style="text-align: left"><b>Hotel Contact</b><div>Hotel Name</div><div>Hotel Full Address</div><div>Hotel Contact</div></div>',
        'abbreviation': 'HC',
        'class': 'l',
        'export': [
          {
            'label': 'Sales Contact Name',
            'type': 'String',
            'field': 'supplier.contact.fullName'
          },
          {
            'label': 'Sales Contact Type',
            'type': 'String',
            'field': 'supplier.contact.company.type'
          },
          {
            'label': 'Sales Contact Job Title',
            'type': 'String',
            'field': 'supplier.contact.jobTitle'
          },
          {
            'label': 'Sales Contact Phone',
            'type': 'String',
            'field': 'supplier.contact.phone'
          },
          {
            'label': 'Sales Contact Email Address',
            'type': 'String',
            'field': 'supplier.contact.emailAddress'
          }
        ]
      },

      'contactName': {
        'id': 'hotelRfpBid.hotel.contactName',
        'type': 'supplierContact',
        'fields': {
          'contactName': 'supplier.contact.fullName',
          'firstName': 'supplier.contact.firstName',
          'lastName': 'supplier.contact.lastName',
          'isUser': 'supplier.contact.isUser',
          'contactId': 'supplier.contact.id',
          'jobTitle': 'supplier.contact.jobTitle',
          'phone': 'supplier.contact.phone',
          'emailAddress': 'supplier.contact.emailAddress',
          'picture': 'supplier.contact.profilePicture',
          'companyType': 'supplier.contact.company.type',
          'invitations': 'supplier.contact.invitations',
          'emailAddressStatus': 'supplier.contact.emailAddressStatus'
        },
        '$sortable': [
          'supplier.contact.fullName',
          'supplier.contact.id'
        ],
        '$groupable': {
          'by': 'supplier.contact.id',
          'text': 'supplier.contact.fullName',
        },
        '$filter': {
          'by': 'supplier.contact.id',
          'type': 'loadList',
          'values': 'supplier.contact.fullName'
        },
        '$groupableColumns': [
          {
            'id': 'supplierContactInvitationStatus',
            'label': 'Supplier Contact Invitation Status',
            '$groupable': {
              'by': 'supplier.contact.invitations.status.value',
              'text': 'supplier.contact.invitations.statusText',
            },
            '$filter': {
              'by': 'supplier.contact.invitations.status.value',
              'type': 'loadList',
              'values': 'supplier.contact.invitations.statusText'
            },
          }
        ],
        '$quickSearch': {
          label: 'Sales Contact Name or Email Address',
          mapFn: bid => {
            if(!bid.supplier.contact) return '';
            return `${bid.supplier.contact.fullName} ${bid.supplier.contact.emailAddress}`
          }
        },
        'label': 'Hotel Sales Contact',
        'description': 'Hotel Contact Name',
        'tooltip': '<div style="text-align: left">Hotel Contact</div>',
        'abbreviation': 'HC',
        'class': 'l',
        'export': [
          {
            'label': 'Sales Contact Name',
            'type': 'String',
            'field': 'supplier.contact.fullName'
          },
          {
            'label': 'Sales Contact Type',
            'type': 'String',
            'field': 'supplier.contact.company.type'
          },
          {
            'label': 'Sales Contact Job Title',
            'type': 'String',
            'field': 'supplier.contact.jobTitle'
          },
          {
            'label': 'Sales Contact Phone',
            'type': 'String',
            'field': 'supplier.contact.phone'
          },
          {
            'label': 'Sales Contact Email Address',
            'type': 'String',
            'field': 'supplier.contact.emailAddress'
          }
        ]
      },

      'distance': {
        'id': 'hotelRfpBid.hotel.distance',
        'type': 'distance',
        'label': 'Distance',
        'description': 'Distance between Travel Destination and Hotel',
        'tooltip': '<div style="text-align: left"><b>Distance</b><div>Distance between Travel Destination and Property</div></div>',
        'abbreviation': 'Dis',
        'fields': {
          'text': 'analytics.distanceMi'
        },
        '$sortable': [
          'analytics.distanceMi'
        ],
        'class': 's',
        'export': [
          {
            'label': 'Distance (mi)',
            'type': 'Decimal',
            'field': 'analytics.distanceMi'
          }
        ]
      },

      'rating': {
        'id': 'hotelRfpBid.hotel.rating',
        'type': 'starRating',
        'label': 'Rating',
        'description': 'Hotel Rating',
        'tooltip': '<div style="text-align: left"><b>Hotel Rating</b></div>',
        'abbreviation': 'RT',
        'fields': {
          'text': 'supplier.company.rating',
          'logo': 'supplier.company.logo'
        },
        '$sortable': [
          'supplier.company.rating'
        ],
        'class': 's',
        'export': [
          {
            'label': 'Hotel Rating',
            'type': 'Integer',
            'field': 'supplier.company.rating'
          }
        ]
      },

      'marketTier': {
        'id': 'hotelRfpBid.hotel.marketTier',
        'type': 'marketTier',
        'label': 'Market Tier',
        'description': 'Market Tier',
        'tooltip': '<div style="text-align: left"><b>Market Tier</b></div>',
        'abbreviation': 'MT',
        'fields': {
          'text': 'supplier.company.marketTier'
        },
        '$sortable': [
          'supplier.company.marketTier'
        ],
        'class': 'xs',
        'export': [
          {
            'label': 'Market Tier',
            'type': 'String',
            'field': 'supplier.company.marketTier'
          }
        ]
      },

      'tripAdvisor': {
        'id': 'hotelRfpBid.hotel.tripAdvisor',
        'type': 'tripAdvisor',
        'label': 'Trip Advisor',
        'description': 'Trip Advisor Hotel Rating',
        'tooltip': '<div style="text-align: left"><b>Trip Advisor Hotel Rating</b></div>',
        'abbreviation': 'TA',
        'fields': {
          'text': 'supplier.company.rating'
        },
        '$sortable': [
          'supplier.company.rating'
        ],
        'class': 's trip-advisor'
      }
    },

    'rateLoading':{
      'id': 'hotelRfpBid.rateLoading',
      'type': 'hidden',
      'fields': {
        'status': 'rateLoading.status',
        'details': 'rateLoading.details',
        'label': 'rateLoading.label',
      },
      '$groupable': {'by': 'rateLoading.label'},
      '$filter': {
        'by': 'rateLoading.label',
        'type': 'loadList',
        'values': 'rateLoading.label'
      },
      'label': 'Rate Loading',
      'Description': 'Are rates loaded into GDS',
      'tooltip': '<div style="text-align: left"></div>',
      'abbreviation': 'RL',
      'class': 'xs',
      'export': [
        {
          'label': 'Rate Loading',
          'type': 'String',
          'field': 'rateLoading.label'
        }
      ]
    },

    'lra':{
      'id': 'hotelRfpBid.lra',
      'type': 'rate',
      'label': 'LRA Rate',
      'fields': {'obj': 'offer.main.lra', 'currency': 'analytics.currency', 'cm': 'offer.main.cm'},
      '$sortable': ['offer.main.lra.amount'],
      'tooltip': '<div style="text-align: left">LRA Rate</div>',
      'abbreviation': 'LRA',
      'class': 'xs rate accent allow-overflow',
      'export': [
        {
          'label': 'LRA Rate',
          'type': 'Rate',
          'field': 'offer.main.lra'
        }
      ]
    },

    'lraAvg':{
      'id': 'hotelRfpBid.lraAvg',
      'type': 'rate',
      'label': 'LRA Rate Weighted Average',
      'fields': {'obj': 'offer.averages.lra', 'currency': 'analytics.currency', 'cm': 'offer.main.cm'},
      '$sortable': ['offer.averages.lra.amount'],
      'tooltip': '<div style="text-align: left">LRA Rate Weighted Average</div>',
      'abbreviation': 'LRA',
      'class': 'xs rate accent averageRate allow-overflow',
      'export': [
        {
          'label': 'LRA Rate Weighted Average',
          'type': 'Rate',
          'field': 'offer.averages.lra'
        }
      ]
    },

    'nlra':{
      'id': 'hotelRfpBid.nlra',
      'type': 'rate',
      'label': 'NLRA Rate',
      'fields': {'obj': 'offer.main.nlra', 'currency': 'analytics.currency', 'cm': 'offer.main.cm'},
      '$sortable': ['offer.main.nlra.amount'],
      'tooltip': '<div style="text-align: left">NLRA Rate</div>',
      'abbreviation': 'NLRA',
      'class': 'xs rate accent allow-overflow',
      'export': [
        {
          'label': 'NLRA Rate',
          'type': 'Rate',
          'field': 'offer.main.nlra'
        }
      ]
    },

    'nlraAvg':{
      'id': 'hotelRfpBid.nlraAvg',
      'type': 'rate',
      'label': 'NLRA Rate Weighted Average',
      'fields': {'obj': 'offer.averages.nlra', 'currency': 'analytics.currency', 'cm': 'offer.main.cm'},
      '$sortable': ['offer.averages.nlra.amount'],
      'tooltip': '<div style="text-align: left">NLRA Rate Weighted Average</div>',
      'abbreviation': 'NLRA',
      'class': 'xs rate accent averageRate allow-overflow',
      'export': [
        {
          'label': 'NLRA Rate Weighted Average',
          'type': 'Rate',
          'field': 'offer.averages.nlra'
        }
      ]
    },

    'govt':{
      'id': 'hotelRfpBid.govt',
      'type': 'rate',
      'label': 'GOVT Rate',
      'fields': {'obj': 'offer.main.govt', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.govt.amount'],
      'tooltip': '<div style="text-align: left">GOVT Rate</div>',
      'abbreviation': 'GOVT',
      'class': 'xs rate allow-overflow',
      'export': [
        {
          'label': 'GOVT Rate',
          'type': 'Rate',
          'field': 'offer.main.govt'
        }
      ]
    },

    'dyn':{
      'id': 'hotelRfpBid.dyn',
      'type': 'rate',
      'label': 'Dynamic Rate',
      'fields': {'obj': 'offer.main.dyn'},
      '$sortable': ['offer.main.dyn.amount'],
      'tooltip': '<div style="text-align: left"><div>Dynamic Rate</div><small>% of BAR</small></div>',
      'abbreviation': 'DYN',
      'class': 'xs rate allow-overflow',
      'export': [
        {
          'label': 'Dynamic Rate',
          'type': 'Rate', // todo ???
          'field': 'offer.main.dyn'
        }
      ]
    },

    'localTax':{
      'id': 'hotelRfpBid.localTax',
      'type': 'value',
      'label': 'Taxes',
      'fields': {'obj': 'offer.main.taxesTotal', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.taxesTotal.isIncluded','offer.main.taxesTotal.amount'],
      'tooltip': '<div style="text-align: left">Taxes Total</div>',
      'abbreviation': 'TX',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Taxes',
          'type': 'Rate',
          'field': 'offer.main.taxesTotal'
        }
      ]
    },

    'cancellationPolicy': {
      'id': 'hotelRfpBid.cancellationPolicy',
      'type': 'value',
      'label': 'Cancellation Policy',
      'fields': {'obj': 'offer.main.cp'},
      '$sortable': ['offer.main.cp.amount'],
      'tooltip': '<div style="text-align: left">Cancellation Policy</div>',
      'abbreviation': 'CP',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Cancellation Policy',
          'type': 'Amenity',
          'field': 'offer.main.cp'
        }
      ]
    },

    'commission': {
      'id': 'hotelRfpBid.commission',
      'type': 'percentageNoDecimals',
      'switch': {
        'id': 'SSLYR:COMMISSION',
        'targetId': 'hotelRfpBid.sslyrDiff',
        'abbr': 'COMM',
        'label': 'Show Commission Offered'
      },
      'label': 'Commission',
      'fields': {'obj': 'offer.main.cm'},
      '$sortable': ['offer.main.cm.amount'],
      'tooltip': '<div style="text-align: left">Show Commission Offered</div>',
      'abbreviation': 'CM',
      'class': 'xs',
      'export': [
        {
          'label': 'Commission',
          'type': 'Amenity',
          'field': 'offer.main.cm'
        }
      ]
    },

    'wifi': {
      'id': 'hotelRfpBid.wifi',
      'type': 'value',
      'label': 'WiFi Rate',
      'fields': {'obj': 'offer.main.wf', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.wf.isIncluded','offer.main.wf.amount'],
      'tooltip': '<div style="text-align: left">WiFi Rate</div>',
      'abbreviation': 'WF',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'WiFi',
          'type': 'Amenity',
          'field': 'offer.main.wf'
        }
      ]
    },

    'breakfast': {
      'id': 'hotelRfpBid.breakfast',
      'type': 'value',
      'label': 'Breakfast Rate',
      'fields': {'obj': 'offer.main.bf', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.bf.isIncluded','offer.main.bf.amount'],
      'tooltip': '<div style="text-align: left">Breakfast Rate</div>',
      'abbreviation': 'BF',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Breakfast',
          'type': 'Amenity',
          'field': 'offer.main.bf'
        }
      ]
    },

    'parking': {
      'id': 'hotelRfpBid.parking',
      'type': 'value',
      'label': 'Parking Rate',
      'fields': {'obj': 'offer.main.prk', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.prk.isIncluded','offer.main.prk.amount'],
      'tooltip': '<div style="text-align: left">Parking Rate</div>',
      'abbreviation': 'PRK',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Parking',
          'type': 'Amenity',
          'field': 'offer.main.prk'
        }
      ]
    },

    'fitness': {
      'id': 'hotelRfpBid.fitness',
      'type': 'value',
      'label': 'Fitness Rate',
      'fields': {'obj': 'offer.main.ft', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.ft.isIncluded','offer.main.ft.amount'],
      'tooltip': '<div style="text-align: left">Fitness Rate</div>',
      'abbreviation': 'FT',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Fitness',
          'type': 'Amenity',
          'field': 'offer.main.ft'
        }
      ]
    },

    'ia': {
      'id': 'hotelRfpBid.hsia',
      'type': 'value',
      'label': 'Internet Access',
      'fields': {'obj': 'offer.main.ia', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.ia.isIncluded','offer.main.ia.amount'],
      'tooltip': '<div style="text-align: left">High Speed internet Access </div>',
      'abbreviation': 'IA',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'High Speed Internet Access',
          'type': 'Amenity',
          'field': 'offer.main.ia'
        }
      ]
    },

    'earlyCheckout': {
      'id': 'hotelRfpBid.earlyCheckout',
      'type': 'value',
      'label': 'Early Checkout Rate',
      'fields': {'obj': 'offer.main.ec', 'currency': 'analytics.currency'},
      '$sortable': [ 'offer.main.ec.isIncluded', 'offer.main.ec.type', 'offer.ec.amount' ],
      'tooltip': '<div style="text-align: left">Early Checkout Rate</div>',
      'abbreviation': 'EC',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Early Checkout',
          'type': 'Amenity',
          'field': 'offer.main.ec'
        }
      ]
    },

    'airportShuttle':{
      'id': 'hotelRfpBid.airportShuttle',
      'type': 'value',
      'label': 'Airport Shuttle Rate',
      'fields': {'obj': 'offer.main.as', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.as.isIncluded', 'offer.main.as.amount'],
      'tooltip': '<div style="text-align: left">Airport Shuttle Rate</div>',
      'abbreviation': 'AS',
      'class': 'xs amenity',
      'export': [
        {
          'label': 'Airport Shuttle',
          'type': 'Amenity',
          'field': 'offer.main.as'
        }
      ]
    },

    'tcos': {
      'id': 'hotelRfpBid.tcos',
      'type': 'value',
      'label': 'Total Cost of Stay',
      'fields': {'obj': 'offer.main.tcos', 'currency': 'analytics.currency'},
      '$sortable': ['offer.main.tcos.amount'],
      'description': 'RFP Total Cost of Stay',
      'tooltip': '<div style="text-align: left">Total Cost of Stay</div>',
      'abbreviation': 'TCOS',
      'class': 'xs tcos score-group',
      'export': [
        {
          'label': 'Total Cost of Stay',
          'type': 'Rate',
          'field': 'offer.main.tcos'
        }
      ]
    },

    'sslyr':{
      'id': 'hotelRfpBid.sslyr',
      'type': 'value',
      'label': 'Last Year Single Standard Rate',
      'fields': {'text': 'analytics.sslyr'},
      '$sortable': ['analytics.sslyr'],
      'description': 'Last Year Single Standard Rate',
      'tooltip': '<div style="text-align: left">Last Year Single Standard Rate</div>',
      'abbreviation': 'SSLYR',
      'class': 'xs',
      'export': [
        {
          'label': 'Last Year Single Standard Rate',
          'type': 'Amount',
          'field': 'analytics.sslyr'
        }
      ]
    },

    'sslyrDiff':{
      'id': 'hotelRfpBid.sslyrDiff',
      'type': 'diff',
      'switch': {
        'id': 'SSLYR:COMMISSION',
        'targetId':'hotelRfpBid.commission',
        'abbr': 'DIFF',
        'label': 'Show Last Year Single Standard Rate Difference'
      },
      'label': 'Last Year Single Standard Rate Difference',
      'fields': {'text': 'analytics.sslyrDif', 'cm': 'offer.main.cm'},
      '$sortable': ['analytics.sslyrDif'],
      'description': 'Last Year Single Standard Rate Difference',
      'tooltip': '<div style="text-align: left">Last Year Single Standard Rate Difference</div>',
      'abbreviation': '% Diff',
      'class': 'xs',
      'export': [
        {
          'label': 'Last Year Single Standard Rate',
          'type': 'String',
          'field': 'analytics.sslyrDifRate'
        },
        {
          'label': 'Last Year Single Standard Rate Difference',
          'type': 'Percentage',
          'field': 'analytics.sslyrDif'
        }
      ]
    },

    'amenities':{
      'id': 'hotelRfpBid.amenities',
      'type': 'amenities',
      'label': '',
      'fields': {'text': 'amenities'},
      'description': 'Amenities',
      'tooltip': '<div style="text-align: left">Click the plus icon to see all amenities</div>',
      'abbreviation': '',
      'class': 'xxxs',
    }

  }
}
