import { writable, get } from 'svelte/store';
import {debounce} from 'lodash';
import {mainAPI} from 'root/angular-injector-provider';

const
  initial = {
    entityType: 'COMPANY',
    query: '',
    searchType: '',
    // results: [],
    results: {
      '': {
        page: 1,
        hasMore: false,
        suggestions: [],
        count: 0
      }
    }
  };

export default function SearchStore(entityType){
  const searchStore = writable({...initial, entityType});

  searchStore.search = debounce(function (query = ''){
    searchStore.update(v => {
      if(v.results[query]){
        return {...v, query};
      } else {
        const page = 1;

        mainAPI().searchEntities(v.entityType, query, page)
          .then(r => {
            searchStore.update(v1 => ({
              ...v1,
              results: {
                ...v1.results,
                [query]: {
                  loading: false,
                  suggestions: r.data,
                  count: r.count,
                  hasMore: r.count > r.data.length,
                  page,
                }},
            }));
          })
          .catch(() => {
            searchStore.update(v1 => ({
              ...v1,
              results: {
                ...v1.results,
                [query]: {
                  loading: false,
                  suggestions: [],
                  count: 0,
                  hasMore: false,
                  page,
                }},
            }));
          });

        return {...v, query, results: {...v.results, [query]: {loading: true} }};
      }
    });
  }, 200);

  searchStore.more = function more(){
    searchStore.update(v => {
      const querySettings = v.results[v.query],
        page = ++querySettings.page;

      mainAPI().searchEntities(v.entityType, v.query, page)
        .then(r => {
          const suggestions = [...querySettings.suggestions, ...r.data],
            count = r.count;

          searchStore.set({
            ...v,
            results: {
              ...v.results,
              [v.query]: {
                loadingMore: false,
                suggestions,
                count,
                hasMore: count > suggestions.length,
                page,
              }},
          });
        })
        .catch(() => {
          searchStore.set({
            ...v,
            results: {
              ...v.results,
              [v.query]: {
                loadingMore: false,
                suggestions: [...querySettings.suggestions],
                hasMore: false,
                page,
              }},
          });
      });

      return {...v, results: {...v.results, [v.query]: {...v.results[v.query], loadingMore: true} }};
    });
  }

  searchStore.setFromId = function (entityId){
    const v = get(searchStore);
    return mainAPI().searchEntityById(v.entityType, entityId);
  }

  return searchStore;
}


