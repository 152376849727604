<template>
  <div>
    <div
      v-responsive
      class="bar no-border"
      :class="$style.bar"
    >
      <hd-select
        v-model="groupBy"
        :dark="true"
        :items="groupers"
      >
        <i
          slot="icon"
          class="material-icons"
        > calendar_view_day </i>
        <span
          v-if="!groupBy"
          slot="label"
        > Group By </span>
      </hd-select>
      <hd-select
        v-if="groupBy"
        v-model="filterBy"
        :dark="true"
        :items="filters"
      >
        <i
          slot="icon"
          class="material-icons"
        > filter_list </i>
        <span slot="label">Filter By</span>
      </hd-select>

      <div :class="$style.controls">
        <button
          v-if="isManagingNotes"
          :title="
            selectedProperties.length === 0
              ? 'Please select at least one hotel'
              : ''
          "
          :disabled="selectedProperties.length === 0"
          class="aBtn asLarge atDark asMain"
          data-icon="notes"
          @click="openNotesDialog"
        >
          Notes
        </button>
        <div :class="$style.managingNotesToggle">
          <label
            :class="{ [$style.active]: !isManagingNotes }"
            @click="isManagingNotes = false"
          >
            Manage Properties
          </label>
          <label
            :class="{ [$style.active]: isManagingNotes }"
            @click="isManagingNotes = true"
          >
            Manage Notes
          </label>
        </div>
        <button
          class="button dark icon-only"
          @click="editColumns"
        >
          <i class="material-icons">settings</i>
          <div
            class="rbTooltip simple"
            tooltip="placement:end-middle"
          >
            Organise Table Columns
          </div>
        </button>
      </div>
    </div>
    <rb-virtual-list
      v-responsive-table
      class="manage-table"
      :class="{
        [$style.table]: true,
        [$style.fullTable]: hotels && hotels.length > 0
      }"
      :items="sortedAndGrouped"
    >
      <template slot="before">
        <div class="row tableHead">
          <div class="cell head checkboxHead">
            <span
              v-if="isManagingNotes"
              class="material-icons check-box"
              @click="toggleAll"
            >
              {{
                selectedPropertyIds.length === filteredHotels.length
                  ? "check_box"
                  : selectedPropertyIds.length > 0
                    ? "indeterminate_check_box"
                    : "check_box_outline_blank"
              }}
            </span>
          </div>
          <template v-for="column in columns">
            <div
              v-if="column === 'rates'"
              :key="column"
              :title="rate.text"
              :style="getColumn(column).style"
              :class="$style.rateColumn"
              class="cell head"
            >
              <hd-select
                v-model="shownRate"
                class="dark-background"
                :dark="true"
                :class="$style.rateDropdown"
                :items="rates"
              >
                <i class="material-icons">info</i>
                <div
                  class="rbTooltip simple"
                  tooltip="placement:bottom-start"
                >
                  Use this dropdown to change the rate type showing in the table
                </div>
                {{ rate.name }}
              </hd-select>
            </div>
            <div
              v-else-if="column === 'code'"
              :key="column + '-code'"
              :title="propertyCode.text"
              :style="getColumn(column).style"
              :class="$style.rateColumn"
              class="cell head"
            >
              <hd-select
                v-model="propertyCodeShown"
                class="dark-background"
                :dark="true"
                :class="$style.rateDropdown"
                :items="propertyCodes"
              >
                <i class="material-icons">info</i>
                <div
                  class="rbTooltip simple"
                  tooltip="placement:bottom-start"
                >
                  Use this dropdown to change the property code type showing in
                  the table
                </div>
                {{ propertyCode.name }}
              </hd-select>
            </div>
            <div
              v-else
              :key="column + '-other'"
              :style="getColumn(column).style"
              class="cell head"
            >
              {{ getColumn(column).text }}
            </div>
          </template>
        </div>
      </template>
      <template slot-scope="item">
        <div
          v-if="
            item.item.type === 'group' &&
              item.item.key &&
              item.item.key !== 'none'
          "
          class="grouper"
        >
          <span
            class="material-icons check-box"
            @click="toggleGroup(item.item.key)"
          >
            {{
              allAddedInGroup(item.item.key)
                ? "check_box"
                : someAddedInGroup(item.item.key)
                  ? "indeterminate_check_box"
                  : "check_box_outline_blank"
            }}
          </span>
          <rb-rating
            v-if="groupBy === 'rating'"
            :rate="item.item.key"
          />
          <span v-else-if="groupBy === 'validity'">{{
            item.item.key ? "Valid" : "With Errors"
          }}</span>
          <span v-else-if="groupBy === 'added'">{{
            item.item.key ? "On Directory" : "Not on Directory"
          }}</span>
          <span v-else>{{ item.item.key }}</span>
        </div>
        <table-row
          v-else
          :key="item.item[id]"
          :row="item.item"
          :added="isItemAdded(item.item)"
          :class="$style.table"
          :columns="columns"
          :rate="shownRate"
          :visible-columns="visibleColumns || 1"
          :open="openedRow === item.item[id]"
          :property-code="propertyCodeShown"
          :property-codes="propertyCodes"
          :updating="updatingRows.indexOf(item.item[id]) >= 0"
          :visible-column-styles="visibleColumnStyles"
          :is-managing-notes="isManagingNotes"
          @add="add"
          @remove="remove"
          @openFinalAgreement="openFinalAgreement"
          @open="openRow"
        />
      </template>
    </rb-virtual-list>
    <div
      v-if="empty"
      :class="$style.empty"
    >
      <div
        v-if="loading"
        class="layout-column flex-center"
        :class="$style.loader"
      >
        <rbv-loading label="Loading, please wait..." />
      </div>
      <template v-else>
        <div :class="$style.emptyTitle">Nothing to see here (:</div>
        <div :class="$style.emptySubTitle">
          Your Hotel Directory shows only those hotels to which you have sent
          Final Agreements on behalf of your company.
        </div>
        <div>
          <button
            class="button dark"
            @click="bidManager()"
          >
            <i class="material-icons">list_alt</i>
            Bid Manager
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import hotelDirectoryService from '../../hotel-directory-service';
import { Notification, Dialog as VueDialog } from 'root/v-app/rbServices';
import ValidityErrorNotification from 'vRoot/hotel-directory/notifications/ValidityErrorNotification.vue';
import HotelsToggledNotification from 'vRoot/hotel-directory/notifications/HotelsToggledNotification.vue';
import ManageNotesDialog from 'vRoot/hotel-directory/modules/manageHotels/manageNotesDialog.vue';

import HotelsMixin from 'vRoot/hotel-directory/_mixins/hotels.vue';
import ResponsiveBarMixin from 'vRoot/hotel-directory/_mixins/responsive-bar.vue';

import rbVirtualList from 'vRoot/_core/RbVirtualList.vue';

import TableRow from './table-row.vue';
import freezeObjectKeys from 'vRoot/hotel-directory/modules/freeze';

export default {
  name: 'HotelDirectoryManageHotels',
  components: { TableRow, rbVirtualList },
  mixins: [HotelsMixin, ResponsiveBarMixin],
  data() {
    return {
      id: 'bidId',
      amenities: [
        'WIFI',
        'Fitness',
        'Internet Access',
        'Early Checkout',
        'Airport Shuttle',
        'Break Fast',
        'Parking'
      ],
      updatingRows: [],
      selectedPropertyIds: [],
      isManagingNotes: false
    };
  },
  mounted() {
    return hotelDirectoryService.getUserAccount().then(user => {
      this.$nextTick(() => this.showTutorial(user));
      this.$nextTick(() => hotelDirectoryService.showOptInDialog());
      return user;
    });
  },
  asyncComputed: {
    hotels() {
      return hotelDirectoryService.getAllFinalAgreementHotels().then(hotels => {
        if (hotels.find(hotel => !hotel.valid)) {
          Notification.show(ValidityErrorNotification, {
            title: 'Important Information',
            subTitle:
              'Some final agreement hotels can not be added to the directory because they contain seasons or rates that are invalid.',
            buttonText: 'Show These Hotels'
          }).then(this.selectErroredHotels);
        }

        return hotels.map(freezeObjectKeys);
      });
    },
    rfps() {
      return hotelDirectoryService.loadRfps();
    }
  },
  methods: {
    checkIfCanAdd(hotel) {
      if (this.isManagingNotes && !hotel.added) {
        Notification.showSnackBar(ValidityErrorNotification, {
          title: 'Invalid Hotel',
          subTitle:
            'You cannot manage notes for a hotel that is not in the directory'
        });
        throw new Error(
          'You cannot manage notes for a hotel that is not in the directory'
        );
      }
    },
    remove(row) {
      const hotel = this.validFilteredHotels.find(
        h => h[this.id] === row[this.id]
      );
      this.bulkRemove([hotel[this.id]]);
    },
    add(row) {
      const hotel = this.validFilteredHotels.find(
        h => h[this.id] === row[this.id]
      );
      this.checkIfCanAdd(hotel);
      this.bulkAdd([hotel[this.id]]);
    },
    bulkAdd(ids) {
      if (!ids.length) return;
      if (this.isManagingNotes) {
        this.selectedPropertyIds = this.selectedPropertyIds.concat(ids);
      } else {
        this.toggleUpdating(ids);
        hotelDirectoryService
          .addHotels(ids)
          .then(() => this.updateHotels(ids, 'add'));
      }
    },
    bulkRemove(ids) {
      if (!ids.length) return;
      if (this.isManagingNotes) {
        this.selectedPropertyIds = this.selectedPropertyIds.filter(
          id => ids.indexOf(id) === -1
        );
      } else {
        this.toggleUpdating(ids);
        hotelDirectoryService
          .removeHotels(ids)
          .then(() => this.updateHotels(ids, 'remove'));
      }
    },
    openFinalAgreement(bidId) {
      hotelDirectoryService.openFinalAgreement(bidId);
    },
    toggleGroup(key) {
      const items = this.groupItems(key);
      if (this.allAddedInGroup(key)) {
        const ids = items
          .filter(item => item.valid)
          .map(hotel => hotel[this.id]);
        this.bulkRemove(ids);
      } else {
        const ids = items
          .filter(item => !this.isItemAdded(item))
          .map(hotel => hotel[this.id]);
        this.bulkAdd(ids);
      }
    },
    updateHotels(ids, action) {
      if (ids.length > 0) {
        this.hotels = this.hotels.map(hotel =>
          ids.includes(hotel.bidId)
            ? { ...hotel, added: action === 'add' }
            : hotel
        );
        this.toggleUpdating(ids);
        this.showNotifications(ids, action);
      }
    },
    showNotifications(ids, action) {
      const hotels = this.validFilteredHotels.filter(
        hotel => ids.indexOf(hotel[this.id]) > -1
      );
      Notification.showSnackBar(HotelsToggledNotification, {
        hotels,
        action
      }).then(data => {
        if (data.action === 'undo' && action === 'add') {
          this.bulkRemove(ids);
        } else if (data.action === 'undo' && action === 'remove') {
          this.bulkAdd(ids);
        }
      });
    },
    bidManager() {
      hotelDirectoryService.openBidManager();
    },
    toggleUpdating(ids) {
      ids.forEach(id =>
        this.updatingRows.indexOf(id) >= 0
          ? this.updatingRows.splice(this.updatingRows.indexOf(id), 1)
          : this.updatingRows.push(id)
      );
    },
    selectErroredHotels() {
      this.groupBy = 'validity';
      this.$nextTick(() => {
        this.filterBy = this.filters.find(filter => !filter.value).id;
      });
    },
    showTutorial(user) {
      if (
        !hotelDirectoryService.stillExploring() &&
        !hotelDirectoryService.userHasSeenManageHotelTutorial(user)
      ) {
        this.showTutorialDialog(user, ({ dontShow }) =>
          hotelDirectoryService.addManageHotelTutorials(dontShow)
        );
      }
    },
    openNotesDialog() {
      VueDialog.show(ManageNotesDialog, {
        hotels: this.selectedProperties,
        propertyIds: this.selectedPropertyIds
      }).then(this.$asyncComputed.hotels.update);
    },
    toggleAll() {
      if (this.selectedPropertyIds.length === this.filteredHotels.length) {
        this.selectedPropertyIds = [];
      } else {
        this.selectedPropertyIds = this.filteredHotels.map(
          hotel => hotel[this.id]
        );
      }
    }
  }
};
</script>
