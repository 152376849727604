import {fileToWorkBook, sheetToJson} from 'root/shared/tools/xls.service'
import { FIELD_ID, FIELD_IATA_CODE, createCityOrOfficeUpload, createAirportUpload } from 'root/rfp-hotel/main/rfp/destinations/upload-destinations.factory'

export default uploadDestinations

function uploadDestinations(file, progressCallback, rfp){
  const destinations = [], fileLoadedProgressValue = 10
  let actionsToBePerformed = 0, actionsPerformed = 0

  return fileToWorkBook(file)
    .then(wb => {
      if(wb.Sheets['Travel Destinations']) {
        return processOfficeOrCityUpload(sheetToJson(wb.Sheets['Travel Destinations']));
      } else if(wb.Sheets['Airport Travel Destinations']) {
        return processAirportsUpload(sheetToJson(wb.Sheets['Airport Travel Destinations']));
      } else {
        return Promise.reject('ERROR')
      }
    })

  function processOfficeOrCityUpload(sheetData){
    return markFileProgress(sheetData)
      .then(parseCityOrOfficeDestinations)
  }

  function processAirportsUpload(sheetData){
    return markFileProgress(sheetData)
      .then(parseAirportDestinations)
  }

  function parseCityOrOfficeDestinations(fileDestinations){
    const rawDestinations = fileDestinations.filter( destination => destinationIsNotEmpty(destination)),
      chain = buildParseChain()
    actionsToBePerformed = rawDestinations.length * 3
    actionsPerformed = 0
    return chain.then(() => destinations)

    function buildParseChain() {
      let chainPromise = Promise.resolve()
      rawDestinations.forEach(rd => {
        chainPromise = chainPromise
          .then(() => createDestination(rd))
          .then(geocodeDestination)
          .then(td => validateDestination(td, rfp.specifications))
          .then(saveDestination)
      })
      return chainPromise
    }

    function destinationIsNotEmpty(td){
      return Object.keys(td).some( field => field !== FIELD_ID && td[ field ].length > 0)
    }

    function createDestination(rd){
      return Promise.resolve(createCityOrOfficeUpload(rd, rfp))
        .then(markTravelDestinationProgress)
    }

    function geocodeDestination(td){
      return td.geocode()
        .then(markTravelDestinationProgress)
    }
  }

  function parseAirportDestinations(fileDestinations){
    const rawDestinations = fileDestinations.filter( destination => {
        const iata = destination[FIELD_IATA_CODE];
        return iata && iata.trim();
      }),
      chain = buildParseChain()
    actionsToBePerformed = rawDestinations.length * 3
    actionsPerformed = 0
    return chain.then(() => destinations)

    function buildParseChain() {
      let chainPromise = Promise.resolve()
      rawDestinations.forEach(rd => {
        chainPromise = chainPromise
          .then(() => createAirportDestination(rd))
          .then(geocodeDestination)
          .then(td => validateDestination(td, rfp.specifications))
          .then(saveDestination)
      })
      return chainPromise
    }

    function createAirportDestination(rd){
      return Promise.resolve(createAirportUpload(rd, rfp))
        .then(markTravelDestinationProgress)
    }

    function geocodeDestination(td){
      return td.setAirportLocation()
        .then(markTravelDestinationProgress)
    }
  }

  function markFileProgress(data){
    progressCallback( fileLoadedProgressValue )
    return Promise.resolve(data)
  }

  function markTravelDestinationProgress(data){
    progressCallback( fileLoadedProgressValue + ( ++actionsPerformed / actionsToBePerformed * (100 - fileLoadedProgressValue) ))
    return Promise.resolve(data)
  }

  function validateDestination(td){
    td.validate()
    return Promise.resolve(td)
      .then(markTravelDestinationProgress)
  }

  function saveDestination(td){
    destinations.push(td)
    return Promise.resolve()
  }
}
