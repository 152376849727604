/**
 * Created by DejanK on 4/12/2017.
 *
 * View Travel Destination Component
 *
 */
import './rb-preview-travel-destination.scss'
import HTML from './rb-preview-travel-destination.html'
import { isNil } from 'lodash'
import { CurrencyFormatter } from 'root/internationalization/internationalization.service';

export default {
  template: HTML,
  bindings: {
    model: '<',
    distanceKm: '<',
    distanceMi: '<'
  },
  controller: Ctrl
}

const NOT_PROVIDED = 'Not Provided';

Ctrl.$inject = [];
function Ctrl() {
  const vm = this;

  vm.model = {}

  vm.printEstimatedRoomNights = () => isNil(vm.model.estimatedRoomNights) ? NOT_PROVIDED : vm.model.estimatedRoomNights;
  vm.printEstimatedSpend = () => CurrencyFormatter(vm.model.currency || 'USD', NOT_PROVIDED).format(vm.model.estimatedSpend);
}
