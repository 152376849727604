import noop from 'lodash/noop';
import HTML from './public-bid-preview.html';
import {SvelteDialog} from 'root/v-app/rbServices';
import HotelRfpSpecificationsReportDialog from 'root/rfp-hotel/main/bid/dialogs/HotelRfpSpecificationsReportDialog.svelte';
import {CHAIN_PORTAL_STATE, BID_MANAGER} from 'root/states';

export { HTML, PublicBidPreviewController as Controller };

PublicBidPreviewController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', 'InvitationService', '$state'];
function PublicBidPreviewController(notificationUtils, RfpHotelBidRepository, Invitation, $state){
  const vm = this;
  let token;

  vm.onRespondToBid = onRespondToBid;
  vm.onToBidManager = onToBidManager;
  vm.onToChainBidManager = onToChainBidManager;
  vm.viewBidPackage = viewBidPackage;

  $onInit();

  function showInvalidInvitationError() {
    notificationUtils.showError('invalid-invitation');
  }

  function $onInit(){
    token = $state.params.token;
    if(token){
      vm.token = token;
      notificationUtils.onLoad( () => RfpHotelBidRepository.getPublicBidPreview(token), vm, 'bid',
        {
          customErrorHandlers: {
            '403': error => {
              showInvalidInvitationError();
              return Promise.reject(error);
            },
            '404': error => {
              vm.error = error;
              return Promise.reject(error);
            }
          }
        })
        .then(onBidLoaded)
        .catch(() => { vm.bid = 'error'; })
    } else {
      showInvalidInvitationError();
    }

    function onBidLoaded(){
      vm.mainButton = 'CHAIN';
      if(vm.bid.data.type === 'DIRECT'){
        vm.mainButton = ['SENT', 'RECEIVED'].indexOf(vm.bid.data.state.id) === -1 ? 'BID_MANAGER' : 'RESPOND';
      }
      const subType = vm.bid.data.specifications.subType;
      vm.isStandardBid = !subType || subType === 'STANDARD';
      vm.isRateQuickBid = subType === 'RATE_QUICK' || subType === 'PER_DIEM';

      if($state.params.direct) {
        onRespondToBid();
      }
    }
  }

  function onToBidManager(){
    Invitation.handle(token)
      .then(({token: t}) => redirect(t, () => $state.go(BID_MANAGER, {viewId: null})))
      .catch( noop );
  }

  function onToChainBidManager(){
    Invitation.handle(token)
      .then(({token: t}) => redirect(t, () => $state.go(CHAIN_PORTAL_STATE, { steps: [`RFP-${vm.bid.data.rfpId}`], chain: 'CHAIN'})))
      .catch( noop );
  }

  function onRespondToBid(){
    Invitation.handle(token)
      .then(({token: t}) => redirect(t, () => $state.go('hotelRfp.bid.response', {bidId: vm.bid.data.id}), noop))
  }

  function redirect(invitationToken, action) {
    return notificationUtils.onSave(() => RfpHotelBidRepository.updateContacts(invitationToken)).then(action);
  }

  function viewBidPackage () {
    SvelteDialog
      .show(HotelRfpSpecificationsReportDialog, {bidId: vm.bid.data.id, hideTravelDestinationInfo: vm.bid.data.type !== 'DIRECT'})
      .catch(noop);
  }
}
