/**
 * Created by DejanK on 03/20/2017.
 */
import {abstractState, simpleState, svelteState} from '../shared/tools/router-helper';
import {USER} from 'auth/auth-roles';

import {
  NO_BID_MANAGER_VIEW_PAGE_STATE,
  PAGE_USER_ACCOUNT_MANAGER,
  PAGE_USER_ACCOUNT_MANAGER_ADD_ACCOUNT
} from 'root/states';

export default States;

States.$inject = ['$stateProvider'];
function States($stateProvider) {
  $stateProvider
    .state('account', abstractState('/account', USER))
    .state(NO_BID_MANAGER_VIEW_PAGE_STATE, simpleState('/no-bm-view', '<rbs-header /><rbv-ng-no-bid-manager-view-module />'))
    .state(PAGE_USER_ACCOUNT_MANAGER, svelteState(
      '/accounts-manager?ref',
      () => import( /* webpackChunkName: "TheUserAccountsManagerPage" */'root/user-account/user-account-manager/TheUserAccountsManagerPage.svelte')))

    .state(PAGE_USER_ACCOUNT_MANAGER_ADD_ACCOUNT, svelteState(
      '/accounts-manager/add-account?type',
      () => import( /* webpackChunkName: "TheTokenUserAccountsManagerAddAccountPage" */'root/user-account/user-account-manager/TheUserAccountsManagerAddAccountPage.svelte')))

}
