import HotelDirectoryModulesLibrary from './modules/HotelDirectoryModulesLibrary';
import {
  noop,
  orderBy
} from 'lodash';
import moment from 'moment';
import {
  notificationUtils,
  httpErrorHandler,
  $state,
  hotelDirectoryRepository,
  currentUser,
  rfpManagerRepository
} from 'root/angular-injector-provider';

import {PAGE_HOTEL_DIRECTORY, BID_MANAGER, SEND_FINAL_AGREEMENT_STATE} from 'root/states';
import ManageLinkDialog from 'vRoot/hotel-directory/dialogs/ManageLinkDialog.vue';

import {
  Dialog
} from 'root/v-app/rbServices';
import OptInDialog from 'vRoot/hotel-directory/dialogs/DirectoryPaymentOptInDialog.vue';

let availableSubModules,
  activeSubModule,
  directory,
  authenticatedUser,
  account,
  optInDialogOpen = false;

const HOTEL_DIRECTORY_PREVIEW_INFO = 'HOTEL_DIRECTORY_PREVIEW_INFO',
  HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL = 'HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL';

export default {
  initialize,
  getActiveSubModule,
  getAccount,
  getDirectory,
  handleActiveSubModuleErrors,
  openSubModule,
  getActiveView,
  getColumn,
  getColumns,
  updateView,
  openView,
  getViews,
  createView,
  deleteView,
  getFinalAgreementHotels,
  getAllFinalAgreementHotels,
  addHotels,
  removeHotels,
  openBidManager,
  addUsers,
  loadAddedUsers,
  loadViewUsers,
  updateUser,
  removeUsers,
  assignView,
  resendUserLink,
  refreshUserLink,
  deactivateUserLink,
  saveTravelPolicy,
  deleteTravelPolicy,
  getViewHotels,
  getUserAccount,
  sendLinkToSelf,
  getMessagePreview,
  getOrCreateLinkForCurrentUser,
  openFinalAgreement,
  fetchTravelDestinations,
  showOptInDialog,
  stillExploring,
  addPreviewTutorial,
  userHasSeenPreviewDialog,
  addManageHotelTutorials,
  userHasSeenManageHotelTutorial,
  manageUserLink,
  loadRfps,
  openActiveViewAsTraveler,
  exportActiveViewToExcel,
  loadLocations,
  downloadLocations,
  saveLocations,
  saveDirectoryNote,
  createDirectoryNote,
  deleteDirectoryNote
}

async function initialize(user, accountId) {
  availableSubModules = availableSubModules ? availableSubModules : HotelDirectoryModulesLibrary.loadModules();
  try {
    activeSubModule = await getActiveSubModule();
    account = await loadAccount(false, accountId);
    await createHotelDirectoryIfNotCreated(account);
    authenticatedUser = user;
    return activeSubModule;
  } catch (err) {
    return handleActiveSubModuleErrors(accountId);
  }

  async function createHotelDirectoryIfNotCreated(acc) {
    if (!acc.hotelDirectoryId) {
      await createHotelDirectoryWithDefaultConfigs(account);
    }
  }
}

function getActiveSubModule() {
  const currentModule = $state().params.submodule,
    active = currentModule && availableSubModules.find(m => m.id === currentModule)
  return active ? Promise.resolve(active) : Promise.reject('no active modules')
}

async function createHotelDirectoryWithDefaultConfigs(acc) {
  try {
    await hotelDirectoryRepository().createHotelDirectoryWithDefaultConfigs(acc);
    account = await getAccount(true);
  } catch (err) {
    httpErrorHandler().handle(err);
  }
}

function handleActiveSubModuleErrors(accountId) {
  if (availableSubModules.length) {
    $state().go(PAGE_HOTEL_DIRECTORY, {
      accountId,
      submodule: availableSubModules[0].id
    }).catch(noop)
  } else {
    $state().go('error', {
      id: '500'
    }).catch(noop)
  }
}

async function loadAccount(force = false, accountId) {
  if (!account || account.id !== accountId || force) {
    const {
      data
    } = await rfpManagerRepository().getAccount(accountId);
    return data.account;
  }
  return account;
}

function getAccount(force) {
  return loadAccount(force, account && account.id);
}

function getUserAccount() {
  return Promise.resolve(authenticatedUser);
}

function getDirectory(force = false) {
  return new Promise(resolve => {
    return (directory && directory.accountId === account.id && !force) ? resolve(directory) : getAccount().then(newAccount => {
      const id = newAccount.hotelDirectoryId;
      return id && hotelDirectoryRepository().getHotelDirectory(id)
        .then(({
          data
        }) => {
          directory = data;
          resolve(directory);
        }, httpErrorHandler().handle);
    });
  })
}

function openSubModule(submodule, data = {
  category: null
}) {
  activeSubModule = Promise.resolve(availableSubModules.find(module => module.id === submodule))
  $state().go(PAGE_HOTEL_DIRECTORY, {
    submodule,
    ...data
  }).catch(noop)
}

function getStateParams() {
  return $state().params;
}

function getColumns() {
  return hotelDirectoryRepository().getColumns();
}

function getColumn(columnId) {
  return getColumns().find(column => column.id === columnId) || {};
}

function updateView(view) {
  return hotelDirectoryRepository().updateView(directory.id, view).then(() => {
    directory.views = directory.views.map(v => {
      return view.id === v.id ? Object.assign({}, view) : v
    })
  }, httpErrorHandler().handle);
}

function getActiveView() {
  const cat_id = getStateParams().view;
  return cat_id && directory && Object.assign({}, directory.views.find(cat => cat.id === cat_id));
}

function openView(view = null) {
  Promise.all([activeSubModule, getDirectory()]).then(data => {
    view = view ? view : data[1].views[0].id;
    openSubModule(data[0].id, {
      view
    })
  });
}

function getViews() {
  return directory && directory.views;
}

function createView(name) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().createView(directory.id, name, account)
  ).then(() => getDirectory(true).then(dir => {
    directory = dir;
    openView(directory.views[directory.views.length - 1].id);
  }));
}

function deleteView(view) {
  return getViews().length > 1 && notificationUtils().onSave(
    () => hotelDirectoryRepository().deleteView(view)
  ).then(() => getDirectory(true).then(() => {
    const activeView = getActiveView();
    if (activeView.id === view.id) {
      openView(directory.views[0].id);
    } else {
      openView();
    }
    directory.views = directory.views.filter(v => v.id !== view.id);
  }));
}

function getFinalAgreementHotels() {
  return new Promise(resolve => {
    hotelDirectoryRepository().getFinalAgreementHotels(account.id)
      .then(httpResponse => {
        resolve(httpResponse.data);
      }, httpErrorHandler().handle);
  });
}

function addHotels(hotels) {
  return hotelDirectoryRepository().addHotels(hotels, directory.id);
}

function getAllFinalAgreementHotels() {
  return new Promise(resolve => {
    hotelDirectoryRepository().getAllFinalAgreementHotels(account.id)
      .then(httpResponse => {
        resolve(httpResponse.data ? httpResponse.data : httpResponse);
      }, httpErrorHandler().handle);
  });
}

function removeHotels(hotels) {
  return hotelDirectoryRepository().removeHotels(hotels, directory.id);
}

function openBidManager() {
  $state().go(BID_MANAGER, {
    viewId: null
  });
}

function addUsers(users = null, message) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().addUsers(users, directory.id, message)
  );
}

function loadAddedUsers() {
  return hotelDirectoryRepository().getAddedUsers(directory.id).then(({data}) => data, httpErrorHandler().handle);
}


function loadViewUsers(viewId) {
  return notificationUtils()
    .onLoadWithNotification(() => hotelDirectoryRepository().getViewUsers(directory.id, viewId))
    .then(({data}) => data, httpErrorHandler().handle);
}

function updateUser(user) {
  return notificationUtils().onSave(() =>
    hotelDirectoryRepository().updateUser(directory.id, user));
}

function removeUsers(users) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().removeUsers(users, directory.id)
  );
}

function assignView(users, viewId) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().assignView(users, viewId, directory.id)
  );
}

function resendUserLink(users, message) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().resendUserLink(users, directory.id, message)
  );
}

function refreshUserLink(userId, message) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().refreshUserLink(userId, directory.id, message)
  );
}

function deactivateUserLink(userId) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().deactivateUserLink(userId, directory.id)
  );
}

function saveTravelPolicy(file, progressCallback) {
  return hotelDirectoryRepository().saveTravelPolicy(directory.id, file, progressCallback)
    .then(httpResponse => httpResponse.data, httpErrorHandler().handle);
}

function deleteTravelPolicy() {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().deleteTravelPolicy(directory.id)
  );
}

function getViewHotels(viewId) {
  return hotelDirectoryRepository().getViewHotels(directory.id, viewId)
    .then(httpResponse => httpResponse.data, httpErrorHandler().handle);
}

function sendLinkToSelf(viewId, messageRequest) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().sendLinkToSelf(viewId, messageRequest)
  );
}

function getOrCreateLinkForCurrentUser(viewId) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().getOrCreateLinkForCurrentUser(viewId)
  );
}

function openFinalAgreement(bidId) {
  $state().go(SEND_FINAL_AGREEMENT_STATE, {
    bidId
  });
}

function fetchTravelDestinations() {
  return hotelDirectoryRepository().getTravelDestinations(directory.id)
    .then(httpResponse => httpResponse.data, httpErrorHandler().handle);
}

function startExploring() {
  return hotelDirectoryRepository().startExploring(directory.id).then(refreshDirectory)
}

async function refreshDirectory() {
  directory = await getDirectory(true);
  return directory;
}

function optInToPay() {
  return hotelDirectoryRepository().optInToPay({
    optingForPayment: true,
    directoryId: directory.id
  }).then(refreshDirectory)
}

function extendTrial() {
  return !directory.trialExtended ? hotelDirectoryRepository().extendTrial(directory.id).then(refreshDirectory) : null;
}

function showOptInDialog() {
  if (!optInDialogOpen && stillExploring()) {
    getUserAccount().then(user => {
      optInDialogOpen = true;
      Dialog.show(OptInDialog, {
        directory,
        user,
        account
      }).then(data => {
        if (data.action === 'explore') {
          startExploring();
        } else if (data.action === 'extend') {
          extendTrial();
        } else {
          optInToPay();
        }
        optInDialogOpen = false;
      }).catch(() => {
        optInDialogOpen = false;
        openBidManager();
      });
    });
  }
}

function stillExploring() {
  return !directory.optingForPayment &&
    !(
      directory.explorationStartDate &&
      moment(new Date()).diff(moment(directory.explorationStartDate), 'days') < 7
    );
}

function addPreviewTutorial(send) {
  return send && hotelDirectoryRepository().addTutorial(HOTEL_DIRECTORY_PREVIEW_INFO).then(() => {
    currentUser().refresh().then(user => (authenticatedUser = user));
  })
}

function addManageHotelTutorials(send) {
  return send && hotelDirectoryRepository().addTutorial(HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL).then(() => {
    currentUser().refresh().then(user => (authenticatedUser = user));
  })
}

function userHasSeenPreviewDialog(user) {
  return user.tutorials.indexOf(HOTEL_DIRECTORY_PREVIEW_INFO) > -1;
}

function userHasSeenManageHotelTutorial(user) {
  return user.tutorials.indexOf(HOTEL_DIRECTORY_MANAGE_HOTELS_TUTORIAL) > -1;
}

async function manageUserLink(user, sendable = true) {
  const {defaultUserMessage} = await getDirectory();
  const userAccount = await getUserAccount();

  Dialog.show(ManageLinkDialog, {
    user,
    message: defaultUserMessage,
    userAccount,
    sendable
  }).then(data => {
    let action;
    if (data.action === 'resend') {
      action = resendUserLink(
        [user.id], {
          message: data.message,
          setMessageAsDefault: data.setMessageAsDefault,
        }
      );
    } else if (data.action === 'deactivate') {
      action = deactivateUserLink(user.id);
    } else {
      action = refreshUserLink(
        user.id, {
          message: data.message,
          setMessageAsDefault: data.setMessageAsDefault,
        }
      );
    }
    return action;
  });
}

function loadRfps(notify = true) {
    const loader = notify? notificationUtils().onLoadWithNotification: notificationUtils().onLoad;
    return loader(() => hotelDirectoryRepository().loadRfps(directory.id)).then(res => res && orderBy(res.data, ['programYear'], ['desc']));
}

async function openActiveViewAsTraveler() {
  const {data: {link}} = await getOrCreateLinkForCurrentUser(getActiveView().id);
  window.open(`${link}/details`, '_blank');
}

async function exportActiveViewToExcel() {
  const dir = await getDirectory();
  const view = getActiveView();
  return notificationUtils().onLoadWithNotification(() => hotelDirectoryRepository().exportHotels(dir.id, view.id));
}

function getMessagePreview(userId, message) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().getMessagePreview(getActiveView().id, {userId, message})
  ).then(({ data }) => data);
}

function loadLocations() {
  return notificationUtils().onLoad(
    () => hotelDirectoryRepository().loadLocations(directory.id)
  ).then(({ data }) => data);
}

function downloadLocations(filename, locations) {
  return notificationUtils().onLoad(() => hotelDirectoryRepository().downloadLocations(filename, locations));
}

function saveLocations(locations) {
  return notificationUtils().onSave(
    () => hotelDirectoryRepository().saveLocations(directory.id, {locations})
  );
}

function saveDirectoryNote(note) {
  return notificationUtils().onSave(() => hotelDirectoryRepository().saveDirectoryNote(note));
}

function createDirectoryNote(note, propertyIds) {
  return notificationUtils().onSave(() => hotelDirectoryRepository().createDirectoryNote(note, propertyIds));
}

function deleteDirectoryNote(note) {
  return notificationUtils().onSave(() => hotelDirectoryRepository().deleteDirectoryNote(note.id));
}