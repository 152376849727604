<template>
  <rbv-dropdown
    :is-open="open"
    :backdrop-style="$style.backdrop"
    @close="close"
  >
    <div
      slot="toggler"
      :class="{ [$style.toggler]: true, [classes]: classes }"
      :style="background"
      @click="toggle"
    >
      <div
        v-if="label"
        :class="$style.label"
      >
        {{ label }}
      </div>
    </div>
    <div
      slot="dropdown"
      :class="$style.dropdown"
    >
      <div
        v-if="label"
        :class="$style.label"
      >
        Color Picker
      </div>
      <canvas
        ref="canvas"
        width="200"
        height="200"
        @click="click"
      />
      <input
        :class="$style.slider"
        type="range"
        min="0"
        step="0.01"
        max="1"
        :value="canvas.alpha"
        @input="changeAlpha"
      >
      <div :class="$style.controls">
        <div :class="$style.inputContainer">
          <div
            :class="$style.colorSpot"
            :style="background"
          />
          <input
            :class="$style.input"
            :value="value"
            @input="update"
          >
        </div>
      </div>
    </div>
  </rbv-dropdown>
</template>

<script>
import RbvDropdown from 'vRoot/_core/RbDropdown.vue';
import image from 'images/rb-color-picker.png';
import { debounce } from 'lodash';

export default {
  name: 'RbColorPicker',
  components: { RbvDropdown },
  props: {
    value: {
      required: true,
      default: '#fff',
      type: String
    },
    label: {
      required: false,
      default: 'Color Picker',
      type: String
    },
    classes: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      open: false,
      canvas: {
        context: null,
        img: new Image(),
        alpha: 1
      }
    };
  },
  computed: {
    background() {
      return { 'background-color': this.value };
    }
  },
  mounted() {
    this.canvas.img.src = image;
    this.canvas.img.onload = this.init;
  },
  methods: {
    close() {
      this.open = false;
    },
    toggle() {
      this.open = !this.open;
      const self = this;
      this.$nextTick(() => self.init());
    },
    init() {
      if (this.$refs.canvas) {
        this.canvas.context = this.$refs.canvas.getContext('2d');
        this.renderCanvas();
      }
    },
    renderCanvas() {
      if (!this.canvas.context) return;
      this.canvas.context.drawImage(
        this.canvas.img,
        0,
        0,
        this.canvas.img.width,
        this.canvas.img.height,
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );

      //render rectangle to modify brightness
      const centerX = this.$refs.canvas.width / 2;
      const centerY = this.$refs.canvas.height / 2;
      const radius = 100;
      const alpha = Math.abs(1 - this.canvas.alpha);
      this.canvas.context.beginPath();
      this.canvas.context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      this.canvas.context.fillStyle = `rgba(0,0,0, ${alpha})`;
      this.canvas.context.fill();
      this.canvas.context.lineWidth = 2;
      this.canvas.context.strokeStyle = '#263238';
      this.canvas.context.stroke();
    },
    click(e) {
      const { x, y } = this.getMousePos(this.$refs.canvas, e);

      // getting image data and RGB values
      const img_data = this.canvas.context.getImageData(x, y, 1, 1).data;
      const R = img_data[0];
      const G = img_data[1];
      const B = img_data[2];
      // convert RGB to HEX
      this.apply(this.rgbToHex(R, G, B));
    },
    rgbToHex(R, G, B) {
      return `#${this.hex(R)}${this.hex(G)}${this.hex(B)}`;
    },
    hex(n) {
      n = parseInt(n, 10);
      if (isNaN(n)) {
        return '00';
      }
      n = Math.max(0, Math.min(n, 255));
      return (
        '0123456789ABCDEF'.charAt((n - (n % 16)) / 16) +
        '0123456789ABCDEF'.charAt(n % 16)
      );
    },
    getMousePos(canvas, e) {
      const rect = canvas.getBoundingClientRect(), // abs. size of element
        scaleX = canvas.width / rect.width, // relationship bitmap vs. element for X
        scaleY = canvas.height / rect.height; // relationship bitmap vs. element for Y

      return {
        x: (e.clientX - rect.left) * scaleX, // scale mouse coordinates after they have
        y: (e.clientY - rect.top) * scaleY // been adjusted to be relative to element
      };
    },
    changeAlpha(e) {
      this.canvas.alpha = e.target.value;
      this.renderCanvas();
    },
    apply(color) {
      this.$emit('input', color);
    },
    update: debounce(function(e) {
      this.apply(e.target.value);
    }, 300)
  },
  destroyed() {
    this.canvas.context = null;
    this.canvas.img = null;
  }
};
</script>

<style lang="stylus" module>
.toggler{
    min-height: 70px;
    width 70px;
    cursor: pointer;
    display flex;
    flex-direction column;
    justify-content center;
    box-sizing border-box;
    border-radius 6px;
    margin 0 2.5px;
}

.label{
    color #ffffff;
    font-size 15px;
    text-align center;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputContainer{
    position relative;
    margin 0;
}

.input{
    background-color: #263238;
    border-radius: 15px;
    color: #fff;
    padding: 10px;
    padding-left: 40px;
    height: 40px;
    box-sizing: border-box;
    width: 100px;
    border 1px solid #1A2226;
}

.colorSpot{
    position absolute;
    top 10px;
    left 10px;
    border-radius 100%;
    height 20px;
    width 20px;
    border 1px solid #1A2226;

}

.dropdown{
    padding: 15px 30px;
    background: #263238;
    bottom: 93px;
    width: 255px;
    box-sizing: border-box;
    position: absolute;
    color #fff;
    border-radius 5px;
    border 1px solid #1A2226;
    display flex;
    flex-direction column;
    justify-content space-around;

    & > *{
        margin 5px 0;
    }
}

.slider{
    width: 100%;
    margin: 0;
    padding: 5px;
    background-image: linear-gradient(to right, #000, #fff);
    border-radius: 15px;

    &::-webkit-slider-thumb {
        border: 2px solid #fff;
        background-color: #000;
        border-radius 15px;
    }

    &::-ms-track{
        background transparent;
        opacity: 1;
    }
}

.controls{
    display flex;
}

.apply{
    height 36px;
    width auto;
    border-radius: 50px;
    padding: 5px 10px;
    display: inline-flex;
    align-items center;
    color #B0BEC5;
    font-size 13px;
    border 1px solid #263238 !important;
    background: #263238;
    border 1px solid #1A2226;

    *{
        vertical-align middle;
    }
}

.backdrop{
    background-color transparent !important;
}
</style>
