import {writable, get} from 'svelte/store';
import {currentUser, mainAPI} from 'root/angular-injector-provider';
import {updateReadMessages} from 'root/messaging/user-messaging.store';
import {isValidDate} from "root/shared/tools/date-tools";

export const
  BID_STATUS = 'BID_STATUS',
  MESSAGE = 'MESSAGE',
  DATE = 'DATE';


const INITIAL_STATE = {bidDetails: null, messages: [], view: []}

const
  formatDate = new Intl.DateTimeFormat('en-US', {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format,
  formatTime = new Intl.DateTimeFormat('en-US', {   hour: 'numeric', minute: 'numeric', }).format;

const store = writable(INITIAL_STATE, () => {

  return () => {
    store.set({...INITIAL_STATE});
  };
});

export default store;

async function initialize(bidId, conversationType){
  store.set({...INITIAL_STATE});

  const httpResult = await mainAPI().messaging.getBidMessagingDetails(bidId);
  const cUser = await currentUser().get();
  const currentUserAccount = cUser.currentUserAccount;

  store.set({
    ...INITIAL_STATE,
    bidDetails: {
      ...httpResult.data,
      userAccount: {
        id: currentUserAccount.id,
        accountId: currentUserAccount.getAccountId(),
        accountType: currentUserAccount.getAccountType(),
        side: currentUserAccount.isBuyer()? ['COMPANY', 'TRAVEL_AGENCY', 'TRAVEL_CONSULTANCY']: ['HOTEL', 'CHAIN', 'HMC'],
        sideId: currentUserAccount.isBuyer()? 'BUYER': 'SUPPLIER',
      }},
    bidStates: prepareBidStatesTrace(httpResult.data.states),
    conversationType
  });

  return loadMessages(bidId, conversationType);
}

function setConversationType(bidId, conversationType){
  if(get(store).bidDetails.bidId !== bidId) return Promise.reject();
  store.update($s => ({...$s, conversationType, messages: [], view: []}));
  return loadMessages(bidId, conversationType);
}

async function sendMessage(bidId, conversationType, newMessage){
  const httpResult = await mainAPI().messaging.sendMessage(bidId, conversationType, newMessage);
  updateStoreMessages(bidId, conversationType, httpResult.data);
}

export const service = {
  initialize,
  setConversationType,
  sendMessage,
}

function prepareBidStatesTrace(statesTrace = []){
  return statesTrace.reduce((acc, s) => {
    const d = new Date(s.at);
    if(isValidDate(d)) {
      acc.push({
        type: BID_STATUS,
        status: s.status,
        label: s.status === 'CREATED'? 'Bid Created': s.label,
        date: formatDate(d),
        time: formatTime(d),
        timestamp: d.getTime(),
      });
    }
    return acc;
  }, []);
}

async function loadMessages(bidId, conversationType){
  const httpResult = await mainAPI().messaging.getBidMessages(bidId, conversationType);
  updateStoreMessages(bidId, conversationType, httpResult.data);
}

function updateStoreMessages(bidId, conversationType, data){
  store.update($s => {
    if($s.bidDetails.bidId === bidId && $s.conversationType === conversationType){
      markReadMessages();
      const messages = prepareMessages(data, $s.bidDetails.userAccount);
      const view = prepareView(messages, $s.bidStates);
      return {...$s, messages, view};
    } else {
      return $s;
    }
  });
}


function markReadMessages(){
  // noinspection JSIgnoredPromiseFromCall
  updateReadMessages();
}

function prepareMessages(data, userAccount){
  return data.map(message => {
    const d = new Date(message.timestamp);
    const from = message.from;
    const side = (!from.accountType? 'ADMIN': (from.userAccountId === userAccount.id? 'OWN': (userAccount.side.includes(from.accountType)? 'SENT': 'RECEIVED')))
    return {
      type: MESSAGE,
      id: message.id,
      timestamp: d.getTime(),
      date: formatDate(d),
      time: formatTime(d),
      text: message.text,
      author: `${from.firstName} ${from.lastName}`,
      email: from.emailAddress,
      accountName: from.accountName,
      side: side,
      userAccountId: from.userAccountId,
      accountId: from.accountId,
      accountType: from.accountType || 'ADMINISTRATOR',
    };
  });
}

function prepareView(messages, states) {
  const merged = [...messages, ...states];
  merged.sort((a,b) => a.timestamp - b.timestamp);

  const view = [];
  let lastDate = null, lastMessage = null;
  for(let i =0, l = merged.length; i<l; i++){
    const m = merged[i];
    if(lastDate !== m.date) {
      view.push({ type: DATE, date: m.date });
      lastDate = m.date;
      lastMessage = null;
    }

    if(m.type === BID_STATUS) {
      lastMessage = null;
      view.push(m);
    }
    else if(m.type === MESSAGE) {
      m.messageContinuation = lastMessage && lastMessage.userAccountId === m.userAccountId && Math.abs(lastMessage.timestamp - m.timestamp) <= 300000;
      lastMessage = m;
      view.push(m);
    }
  }

  return view.reverse();
}
