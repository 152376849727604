/**
 * Stores view config - static derivatives from the loaded view
 */

import {writable} from 'svelte/store';
import {default as savedViewStore, initialize as initializeViewConfigStore} from 'rfp/bid-manager/main/stores/view-saved';
import bmTableColumns from 'root/assets/data/view-headings';
import {forEach, get, orderBy} from 'lodash';

const store = writable({}, () => {
  const svsUnsubscribe = savedViewStore.subscribe($svs => {
    if (!$svs.initialized) return;
    store.update($s => ({
      ...$s,
      $settings: $svs.settings,
      id: $svs.$viewRaw.id,
      name: $svs.$viewRaw.name,
      side: $svs.$viewRaw.side,
      type: $svs.$viewRaw.type,
      rfpType: $svs.$viewRaw.rfpType,
      rfpId: $svs.$viewRaw.rfpId,
      ownerId: $svs.$viewRaw.owner,
      userAccountType: $svs.userAccountType,

      columns: $svs.columns,
      groups: getGroupByOptions($svs.columns),
      querySort: loadQuerySort($svs.$viewRaw.sort, bmTableColumns),
      queryGroup: get(bmTableColumns, $svs.$viewRaw.group),
      queryFilter: loadQueryFilter($svs.$viewRaw.filter, bmTableColumns),
      quickSearchConfig: createQuickSearchConfig($svs.columns),
      initialized: true,
    }));
  });

  return () => {
    svsUnsubscribe();
    store.set({});
  }
});

export default store;

export function initialize(config) {
  return initializeViewConfigStore(config)
}

function getGroupByOptions(columns){
  let listOfGroupableColumns = getGroupableColumns(columns);
  listOfGroupableColumns = orderBy(listOfGroupableColumns, ['label']);
  listOfGroupableColumns.unshift({label: 'None', id: null});
  return listOfGroupableColumns;

  function getGroupableColumns(cs) {
    return cs.reduce((acc, column) => {
      if(column.$groupable) {
        acc = [...acc, column];
      }

      if(column.$groupableColumns && column.$groupableColumns.length > 0) {
        acc = [...acc, ...getGroupableColumns(column.$groupableColumns)];
      }

      return acc;
    }, []);
  }
}

function loadQuerySort(sortObj, cData){
  const initialized = {};
  forEach(sortObj, (direction, key)=>{
    const c = get(cData, key);
    if(c) {
      initialized[key] = { by: c, direction: direction};
    }
  });
  return initialized;
}

function loadQueryFilter(filterObj, cData){
  const initialized = {};
  forEach(filterObj, (filterValue, key)=>{
    const c = get(cData, key);
    if(c) {
      initialized[key] = { by: c, value: filterValue };
    }
  });
  return initialized;
}

function createQuickSearchConfig(columns = []){
  const searchable = columns.filter(btc => btc.$quickSearch).map(btc => btc.$quickSearch),
    searchableLength = searchable.length;

  if(searchableLength === 0) {
    return null;

  } else if (searchableLength === 1) {
    return searchable[0];

  } else {
    return searchable.reduce((acc, s, index) => ({
      label: index === 0 ? s.label : `${acc.label}${index === searchableLength-1 ? ' or ' : ', '}${s.label}`,
      mapFn: index === 0 ? s.mapFn : bid => `${acc.mapFn(bid)}; ${s.mapFn(bid)}`
    }),
      {label: '', mapFn: undefined});
  }
}
