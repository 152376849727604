<template>
  <modal-form
    class="vModal vModalDefault"
    :class="$style.dialog"
    @cancel="$formCancelHandler"
    @submit="$formSubmitHandler"
  >
    <div
      slot="header"
      :class="$style.header"
    >
      <i
        class="material-icons"
        :class="$style.warning"
      >info_outline</i>
      <div :class="$style.texts">
        <div>{{ title }}</div>
      </div>
    </div>
    <div
      slot="body"
      class="vModalDefaultBody hotel-directory"
    >
      <div>{{ subTitle }}</div>
    </div>
    <div
      slot="footer"
      class="vModalDefaultFooter"
    >
      <button
        v-if="buttonText"
        class="rbButton mainButton"
        :class="$style.mainButton"
      >
        <i class="material-icons">check</i>
        {{ buttonText }}
      </button>
    </div>
  </modal-form>
</template>

<script>

  import ModalForm from 'vRoot/_core/RbModalForm.vue'
  import DialogMixin from 'vRoot/_mixins/DialogMixin.vue'
  import RbFormMixin from 'root/v-components/_mixins/RbFormMixin.vue'

  export default {
    name: 'ValidityErrorNotification',
    components: { ModalForm },
    mixins: [ RbFormMixin, DialogMixin ],
    props: ['title', 'subTitle', 'buttonText'],
    methods: {
      onSubmit(){
        return {};
      },
    }
  }
</script>

<style lang="stylus" module>

  .dialog {
    width: 400px;
    overflow visible
    border-radius 1px solid;
  }

  .subHeader{
    font-size 25px;
    line-height 14pt;
    color #78909c;
    font-weight lighter;
  }

  .header{
    display flex;
    font-weight lighter;
  }

  .warning{
    height: 50px;
    width: 50px;
    font-size: 50px;
    color: rgba(242, 60, 24, 0.8);
    margin-right 10px;
  }

  .texts{
    padding 5px;
    font-size: 18px;
    color: #78909c;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mainButton{
    background : rgba(242, 60, 24, 0.8) !important;
    border none !important;
  }
</style>
