import {writable, get} from 'svelte/store';
import {currentUser, mainAPI} from 'root/angular-injector-provider';

const MINIMUM_OBJECT_ID = generateObjectIdFromAYearAgo();

const INITIAL_STATE = {
  _storage: {
    id: '',
    timeId: '',
  },
  data: {}
}

const store = writable(INITIAL_STATE);

export default store;

let activeRequestId = null;
let currentUserAccountId = null;
let oldestUnreadMessage = null;

(function loop() {
  setTimeout(async () => {
    await updateReadMessages();
    loop();
  }, 60000);
})();



export function initialize() {
  store.set(INITIAL_STATE);
  currentUser().watch(initializeStore);
}

async function initializeStore(){
  store.set(INITIAL_STATE);
  activeRequestId = null;
  currentUserAccountId = null;
  oldestUnreadMessage = null;

  const _storage = await initializeStorage();
  if(_storage.id) {
    const data = JSON.parse(localStorage.getItem(_storage.id) || '{}');
    const lastUpdated = localStorage.getItem(_storage.timeId) || null;
    store.set({_storage, data, lastUpdated});
    currentUserAccountId = _storage.currentUserAccountId;
    oldestUnreadMessage = MINIMUM_OBJECT_ID > currentUserAccountId? MINIMUM_OBJECT_ID : currentUserAccountId;
    await updateReadMessages();
  }
}

export async function updateReadMessages(){
  if(activeRequestId || !currentUserAccountId) return;
  const thisRequestId = new Date().getTime();
  activeRequestId = thisRequestId;

  try {
    const $store = get(store);
    const httpResponse = await mainAPI().messaging.getCurrentUserReadMessages($store.lastUpdated || null);
    const rData = httpResponse.data;

    if (rData && rData.userAccountId === currentUserAccountId && thisRequestId === activeRequestId) {
      store.update($s => {
        const lastUpdated = rData.updated || null;
        const data = {...$s.data, ...(rData.data || {})}
        localStorage.setItem($s._storage.timeId, lastUpdated);
        localStorage.setItem($s._storage.id, JSON.stringify(data));
        return {...$s, data, lastUpdated};
      });
    }
  } catch (error) {
    console.error(error);
  }

  if(thisRequestId === activeRequestId) {activeRequestId = null;}
}

export function isUnread(subject, lastMessageId) {
  if(lastMessageId < oldestUnreadMessage) return false;
  const lastReadMessageId = get(store).data[subject];
  return !lastReadMessageId || lastReadMessageId < lastMessageId;
}



async function initializeStorage(){
  const cu = await currentUser().getActiveUserAccount();
  const id = `RB-MSG-${cu.id}`;
  const timeId = `${id}_TIME`;

  return {
    id, timeId, currentUserAccountId: cu.id
  };
}


function generateObjectIdFromAYearAgo(){
  const t = parseInt((new Date().getTime()/1000) - 31622400, 10) // current seconds - seconds in a year;
  return `${hex(8,t % 0xFFFFFFFF)}0000000000000000`;

  function hex(length, n) {
    n = n.toString(16);
    return (n.length===length)? n : '00000000'.substring(n.length, length) + n;
  }
}
