import SvelteNgComponent from 'root/svelte-ng-components/SvelteNgComponent.js';
import Component from './PublicLinkErrorHandler.svelte';

export default {
  template: '<div></div>',
  bindings: {
    error: '<',
  },
  controller: Ctrl
}

Ctrl.$inject = ['$element'];
function Ctrl($element){
  const vm = this;

  Object.assign(vm, SvelteNgComponent(Component, $element[0], () => ({
    error: vm.error,
  })));
}
