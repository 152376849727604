import Moment from 'moment'

export { formatDate, loadDate, daysBetween, toIsoUtc, isValidDate }

export const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;

/**
 *
 * @param dateString ISO-8601
 */
function formatDate(dateString, format, defaultValue) {
  const m = Moment(dateString);
  return m.isValid() ? m.format( format || 'YYYY/MM/DD' ) : defaultValue || ''
}

function loadDate(dateString) {
  if (/\d{4}-\d{2}-\d{2}/.test(dateString)){
    return new Date(dateString);
  } else {
    const excelDate = new Date(1899, 11, 30);
    return new Date(excelDate.getTime() + parseInt(dateString, 10)*MILLIS_PER_DAY);
  }
}

function daysBetween(start, end) {
  return Math.ceil(Math.abs(end - start) / MILLIS_PER_DAY);
}

function toIsoUtc(date){
  const dUtc = date.toISOString();
  return dUtc.slice(0, 10);
}

/**
 * checks if date is a valid Date object
 * @param {Date} date a Date object to check
 * @returns {boolean} is valid Date object
 */
function isValidDate(date){
    return date instanceof Date && !isNaN(date);
}
