<script>
  import ClosableModal from 'root/services/modals/Closable.modal.svelte';
  import ModalTitle from 'root/services/modals/ModalTitle.modal.svelte';
  import {notificationUtils} from 'root/angular-injector-provider';
  import SpinnerWithText from 'root/components/molecules/SpinnerWithText.svelte';
  import {default as store, service } from './bid-messaging.store'
  import TextArea from 'root/components/molecules/inputs/TextArea.labeled.svelte'
  import {default as readMessagesStore, isUnread} from 'root/messaging/user-messaging.store';
  import createBidMessagingSubject from 'root/messaging/bid-messaging-subject';

  export let hide, bidId;

  const TYPE_PUBLIC = 'PUBLIC', TYPE_PRIVATE = 'PRIVATE', TYPE_PERSONAL = 'PERSONAL';

  let newMessage = '', MessagesElement, loadingMessages = false, unreadMessages = {};

  $: loadBidMessagingDetails(bidId);
  $: unreadMessages = checkUnreadMessages($store.bidDetails, readMessagesStore)

  async function loadBidMessagingDetails(id) {
    loadingMessages = true;
    await notificationUtils().onLoad(() => service.initialize(id, TYPE_PUBLIC));
    loadingMessages = false;
  }

  async function setConversationType(type){
    loadingMessages = true;
    await service.setConversationType(bidId, type);
    loadingMessages = false;
  }

  async function sendMessage(){
    if(newMessage && newMessage.trim()){
      await notificationUtils().onSave(() => service.sendMessage(bidId, $store.conversationType, newMessage))
      newMessage = '';
      MessagesElement.firstElementChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  function checkUnreadMessages(bidDetails = {}, rmStore){
    if(!rmStore) return {};
    const lastMessages = bidDetails && bidDetails.messages || {};
    return Object.keys(lastMessages).reduce((acc, b) => {
      acc[b] = isUnread(createBidMessagingSubject(bidId, b), lastMessages[b]);
      return acc;
    }, {});
  }

</script>

{#if $store.bidDetails}
  <ClosableModal close="{hide}" width="{'800px'}">
    <div slot="heading">
      <ModalTitle title="{$store.bidDetails.rfpName} Bid Messages">
        <div style="display: flex; justify-content: space-between">
          <div>
            <div><span>FOR: </span>{$store.bidDetails.hotelName}</div>
            <div>{$store.bidDetails.hotelAddress}</div>
          </div>

          <div>
            <div><span>RFP Issuer: </span>{$store.bidDetails.buyerContactCompanyName}</div>
            <div><span>RFP Year: </span>{$store.bidDetails.programYear}</div>
          </div>
        </div>
      </ModalTitle>
    </div>

    <div class="Buckets" >
      <div>
        <button class="aBtn asSmall atLight Bucket"
                class:asMain={$store.conversationType === TYPE_PUBLIC}
                on:click={() => setConversationType(TYPE_PUBLIC)}
        >
          Messages (<span class="MessagesCount" class:Unread={unreadMessages[TYPE_PUBLIC]}>{$store.bidDetails.messagesCounts.PUBLIC}</span>)
        </button>
        <div class="rbTooltip" data-tooltip="placement:bottom">
          Conversation between buyers and suppliers.
        </div>
      </div>

      <div>
        <button class="aBtn asSmall atLight Bucket"
                class:asMain={$store.conversationType === TYPE_PRIVATE}
                on:click={() => setConversationType(TYPE_PRIVATE)}
        >
          Notes (<span class="MessagesCount" class:Unread={unreadMessages[TYPE_PRIVATE]}>{$store.bidDetails.messagesCounts.PRIVATE}</span>)
        </button>

        <div class="rbTooltip" data-tooltip="placement:bottom">
          Conversation between {$store.bidDetails.userAccount.sideId === 'BUYER'? 'buyers': 'suppliers'} only.
        </div>
      </div>

      <div>
        <button class="aBtn asSmall atLight Bucket"
                class:asMain={$store.conversationType === TYPE_PERSONAL}
                on:click={() => setConversationType(TYPE_PERSONAL)}
        >
          Personal Notes (<span class="MessagesCount" class:Unread={unreadMessages[TYPE_PERSONAL]}>{$store.bidDetails.messagesCounts.PERSONAL}</span>)
        </button>

        <div class="rbTooltip" data-tooltip="placement:bottom">
          Notes visible only to you.
        </div>
      </div>
    </div>

    <div class="Messages" bind:this={MessagesElement}>
      {#if loadingMessages}
        <SpinnerWithText />
      {:else}
        {#each ($store.view || []) as item}
          {#if item.type === 'MESSAGE'}
            <div class="MessageContainer {item.side}">
              <div class="Message">
                {#if !item.messageContinuation}
                  <div class="MessageTitle {item.side}">{item.time} {#if item.side !== 'OWN'} &bullet; {item.author} &bullet; {item.accountName}{/if}</div>
                {/if}
                <div class="MessageBody {item.side}">{item.text}</div>
              </div>
            </div>
          {:else if item.type === 'DATE'}
            <div class="DateSeparator">{item.date}</div>
          {:else if item.type === 'BID_STATUS'}
            <div class="BidStatus">
              {item.time} &bullet; {item.label}
            </div>
          {/if}
        {/each}
      {/if}
    </div>

    <div class="NewMessageForm">
      <div class="NewMessageTextArea">
        <TextArea bind:value="{newMessage}"
                  placeholder="Type a message"
                  maxLength="1000"
                  maxHeight="100px"
        >
          New Message
        </TextArea>
      </div>
      <button class="aBtn asSmall atLight SendMessage" on:click={sendMessage}>Send</button>
    </div>
  </ClosableModal>
{:else}
  <SpinnerWithText />
{/if}

<style lang="stylus">.Buckets {
  display: flex;
  gap: 10px;
  margin-top: -15px;
  border: 1px solid #90a4ae;
  border-left: 0;
  border-right: 0;
  padding: 5px 10px 0;
}
.aBtn.Bucket {
  border-radius: 12px 12px 0 0;
  margin-bottom: -1px;
  border-bottom: 1px solid #90a4ae;
}
.aBtn.Bucket.asMain .MessagesCount {
  color: #fff !important;
}
.MessagesCount {
  color: #b0bec5;
}
.MessagesCount.Unread {
  color: #00a99d;
  font-weight: bold;
}
.Messages {
  height: 50vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
.MessageContainer {
  display: flex;
}
.MessageContainer.OWN,
.MessageContainer.SENT {
  justify-content: flex-end;
  padding: 0 10px 0 30%;
}
.MessageContainer.RECEIVED,
.MessageContainer.ADMIN {
  justify-content: flex-start;
  padding: 0 30% 0 10px;
}
.Message {
  margin: 2px 10px;
}
.MessageTitle {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #78909c;
}
.MessageTitle.OWN,
.MessageTitle.SENT {
  text-align: right;
}
.MessageBody {
  white-space: pre-line;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  background: #eceff1;
  color: #37474f;
}
.MessageBody.OWN {
  border-radius: 10px 10px 0 10px;
  background: #0c3160;
  color: #fff;
}
.MessageBody.SENT {
  border-radius: 10px 0 10px 10px;
}
.MessageBody.RECEIVED {
  border-radius: 0 10px 10px 10px;
}
.MessageBody.ADMIN {
  border-radius: 0 10px 10px 10px;
  background: #00a99d;
  color: #fff;
}
.DateSeparator {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #546e7a;
  border-top: 1px solid #b0bec5;
  margin: 5px 30px;
}
.BidStatus {
  margin: 5px auto;
  padding: 5px 20px;
  font-size: 13px;
  line-height: 16px;
  background: #f15a24;
  color: #fff;
  border-radius: 13px;
}
.NewMessageForm {
  display: flex;
  padding: 20px;
  align-items: flex-end;
}
.NewMessageTextArea {
  flex: 1 1 100%;
  padding-right: 10px;
}
button.SendMessage {
  height: 42px;
}
</style>
