/**
 * Created by DejanK on 9/29/2017.
 */
import HTML from './view-final-agreement.html'
import '../../../rfp/pages/final-agreement/view-final-agreement.scss'
import autoSave from 'root/shared/tools/file-autosave'
import scrollToAttachments from 'root/rfp-hotel/attachments/scroll-to-attachments.service';
import noop from 'lodash/noop';

export { HTML, BidFinalAgreementViewController as Controller }

BidFinalAgreementViewController.$inject = ['$stateParams', 'NotificationUtils', 'MainAPI'];
function BidFinalAgreementViewController($stateParams, notificationUtils, mainAPI){
  const vm = this;

  vm.exportAsPdf = exportAsPdf;
  vm.exportAsExcel = exportAsExcel;
  vm.toAttachments = () => scrollToAttachments();

  $onInit();

  function $onInit(){
    vm.bidId = $stateParams.bidId;
    vm.token = $stateParams.token;
    mainAPI.viewHotelRfpBidFinalAgreement(vm.bidId)
      .then(r => {
        vm.fa = r
      })
      .catch(e => {
        vm.error = e;
      });
  }

  function exportAsPdf(){
    notificationUtils.onLoadWithNotification( () => mainAPI.exportFinalAgreementAsPdf(vm.bidId) )
      .then( (response) => { autoSave(response, 'application/pdf', `Final Agreement.pdf`) } )
      .catch(noop);
  }

  function exportAsExcel(){
    notificationUtils.onLoadWithNotification( () => mainAPI.exportFinalAgreementAsExcel(vm.bidId) )
      .catch(noop);
  }
}
