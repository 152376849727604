<template>
  <rb-modal
    :options="{
      labels: { buttons: { apply: 'Create new note', cancel: 'Cancel' } }
    }"
    @hide="createOrEditNote()"
    @cancel="hide()"
  >
    <div slot="header">
      <div :class="$style.Header">
        Manage Notes
      </div>

      <p :class="$style.Text">
        Manage notes for selected hotels
      </p>
    </div>
    <div
      slot="body"
      :class="$style.Body"
    >
      <template v-if="sharedNotes.length || otherNotes.length">
        <div v-if="sharedNotes.length">
          <div
            :class="$style.GroupTitle"
            @click="toggleGroup('shared')"
          >
            Shared Notes
            <i class="material-icons">{{
              openCarouselGroup.indexOf("shared") > -1
                ? "arrow_drop_up"
                : "arrow_drop_down"
            }}</i>
          </div>
          <div
            :class="{
              [$style.GroupBody]: true,
              [$style.open]: openCarouselGroup.indexOf('shared') > -1
            }"
          >
            <div
              v-for="note in sharedNotes"
              :key="note.id"
            >
              <div :class="$style.Note">
                <div
                  :class="$style.color"
                  :style="{ backgroundColor: note.color }"
                />
                <div :class="$style.Text">{{ note.note }}</div>
                <div
                  :class="['button main', $style.EditButton]"
                  @click="createOrEditNote(note)"
                >
                  <i class="material-icons">edit</i>
                </div>
                <div
                  class="button dark"
                  @click="deleteNote(note)"
                >
                  <i class="material-icons">delete</i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="otherNotes.length">
          <div
            :class="$style.GroupTitle"
            @click="toggleGroup('others')"
          >
            Other Notes
            <i class="material-icons">{{
              openCarouselGroup.indexOf("others") > -1
                ? "arrow_drop_up"
                : "arrow_drop_down"
            }}</i>
          </div>
          <div
            :class="{
              [$style.GroupBody]: true,
              [$style.open]: openCarouselGroup.indexOf('others') > -1
            }"
          >
            <div
              v-for="note in otherNotes"
              :key="note.id"
            >
              <div :class="$style.Note">
                <div
                  :class="$style.color"
                  :style="{ backgroundColor: note.color }"
                />
                <div :class="$style.Text">{{ note.note }}</div>
                <div
                  :class="['button main', $style.EditButton]"
                  @click="createOrEditNote(note)"
                >
                  <i class="material-icons">edit</i>
                </div>
                <div
                  class="button dark"
                  @click="deleteNote(note)"
                >
                  <i class="material-icons">delete</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        :class="$style.Empty"
      >
        <div>Nothing to see here yet</div>
        <div :class="$style.Text">
          No notes have been added yet. Click the button below to add a note.
        </div>
        <button
          class="aBtn asLarge atLight asMain"
          @click="createOrEditNote()"
        >
          <i class="material-icons">add</i>
          Add Note
        </button>
      </div>
    </div>
  </rb-modal>
</template>

<script>
import { Dialog as VueDialog } from 'root/v-app/rbServices';
import CreateOrEditNotesDialog from './createOrEditNotesDialog.vue';
import DialogMixin from 'vRoot/_mixins/DialogMixin.vue';
import RbModal from 'vRoot/_core/RbModal.vue';

import WarningDialog from 'vRoot/hotel-directory/dialogs/WarningDialog.vue';
import hotelDirectoryService from '../../hotel-directory-service';

export default {
  name: 'ManageNotesDialog',
  components: { RbModal },
  mixins: [DialogMixin],
  props: {
    hotels: {
      type: Array,
      default: () => []
    },
    propertyIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openCarouselGroup: ['shared'], // options are shared and others,
      sharedNotes: this.getSharedNotes(),
      otherNotes: this.getOtherNotes()
    };
  },
  methods: {
    getSharedNotes() {
      return this.hotels.reduce((acc, hotel) => {
        const notes = hotel.notes || [];
        if (!acc) return notes;
        return acc.filter(note => notes.some(n => n.id === note.id));
      }, null);
    },
    getOtherNotes() {
      const sharedNotes = this.getSharedNotes();
      return Object.values(
        this.hotels
          .flatMap(hotel => hotel.notes || [])
          .filter(note => !sharedNotes.some(n => n.id === note.id))
          .reduce((acc, note) => ({ [note.id]: note, ...acc }), {})
      );
    },
    toggleGroup(group) {
      const index = this.openCarouselGroup.indexOf(group);
      if (index > -1) {
        this.openCarouselGroup = this.openCarouselGroup.filter(
          g => g !== group
        );
      } else {
        this.openCarouselGroup = this.openCarouselGroup.concat(group);
      }
    },
    createOrEditNote(note) {
      VueDialog.show(CreateOrEditNotesDialog, {
        note,
        propertyIds: this.propertyIds
      }).then(data => {
        if (note) {
          this.sharedNotes = this.sharedNotes.map(n =>
            n.id === note.id ? data : n
          );
          this.otherNotes = this.otherNotes.map(n =>
            n.id === note.id ? data : n
          );
        } else {
          this.hide();
        }
      });
    },
    deleteNote(note) {
      VueDialog.show(WarningDialog, {
        title: 'Delete note',
        subTitle:
          'Are you sure you want to delete this note? This action cannot be undone.'
      })
        .then(() => hotelDirectoryService.deleteDirectoryNote(note))
        .then(() => {
          this.sharedNotes = this.sharedNotes.filter(n => n.id !== note.id);
          this.otherNotes = this.otherNotes.filter(n => n.id !== note.id);
        });
    }
  },
};
</script>

<style lang="stylus" module>
.Header {
  font-weight : bold;
  font-size : 14px;
  color : #37474F;
}

.Text {
  font-size : 12px;
  color : #78909C;
  margin: 5px 0;
}

.Body {
  width: 500px;
  max-height: 60vh;
  overflow-y: auto;
  border 1px solid #546f7a4f;
  color: #37474f;
}

.GroupTitle {
  display: flex;
  align-items: center;
  justify-content space-between;
  padding: 8px 12px;
  border-bottom: 1px solid #546f7a4f;
  cursor pointer;
  font-size: 16px;
}

.GroupBody {
  max-height: 0vh;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.GroupBody.open {
  max-height: 100vh;
  overflow: auto;
}

.Note {
  composes: GroupTitle;
  font-size: 12px;
  gap: 10px;
}

.Note .Color {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 2px solid #cbd2d5;
}

.Note .Text {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space nowrap;
}

.Note .EditButton i {
  color: #00B8AB;
}

.Empty {
  display flex;
  flex-direction column;
  gap: 5px
  padding: 10px;
  justify-content center
  align-items center;
}

.Empty > div:first-child {
  color: #37474f;
  font-size 16px;
}

.Empty button {
  display: flex;
  align-items: center;
}
</style>
