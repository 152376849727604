<script>
  import Spinner from 'root/components/atoms/Spinner.svelte';
</script>

<div class="rbCenter">
  <Spinner />
  <div><slot>Loading, please wait...</slot></div>
</div>

<style lang="stylus">div {
  color: #78909c;
  font-weight: 100;
  margin: 15px auto 0;
  font-size: 11pt;
  padding-left: 11pt;
}
</style>
