import HTML from './rb-airport-autocomplete.html'
import { noop } from 'lodash'
import {stopEvent} from 'root/shared/tools/view-utils';
import './rb-airport-autocomplete.scss'

export default {
  template: HTML,
  bindings: {
    onSelect: '&',
    onAddManually: '&',
    types: '<',
    selected: '<',
    showAddManually: '<'
  },
  controller: Controller
}

Controller.$inject = ['NotificationUtils', '$scope', 'MainAPI']
function Controller(notificationUtils, $scope, MainAPI){
  const vm = this

  vm.config = {
    toggler: {
      element: 'input',
      events: { open: ['focus', 'keydown'], close: 'blur'}
    }
  }

  vm.$onChanges = $onChanges
  vm.stopEvent = stopEvent
  vm.onQueryChange = onQueryChange
  vm.onSelected = onSelected
  vm.addManually = () => { vm.onAddManually() }

  function $onChanges(changes){
    if(changes.selected && changes.selected.currentValue){
      $scope.$timeout( () => {
        vm.onQueryChange = noop
        vm.query = `${changes.selected.currentValue.iata} - ${changes.selected.currentValue.name}`
        vm.onQueryChange = onQueryChange
      })
    }

    if(changes.types){
      vm.query = '';
    }
  }

  function onQueryChange() {
    const query = vm.query;
    if(query && query.length){
      vm.loading = true
      MainAPI.searchAirports(query)
        .then( result => {
          $scope.$timeout(()=> {
            if(vm.query === query) {
              vm.airports = result? result.data.data: [];
              vm.loading = false;
            }
          })
        }, noop)
    } else {
      vm.airports = [];
    }
  }

  function onSelected($event, airport){
    stopEvent($event)
    $scope.$timeout( () => {
      vm.onSelect({$event: { airport }})
    })
  }
}
