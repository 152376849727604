import {SvelteDialog} from 'root/v-app/rbServices';
import noop from 'lodash/noop';
import SetRateAccessCodeDialog from './SetRateAccessCodeDialog.svelte';


export function SetBidsRateAccessCode(){
  return function (selectedBidsReport){
    return {
      label: `Set Bid${selectedBidsReport.bids.length > 1? 's' : ''} Rate Access Code`,
      icon: '123',
      isAvailable: () => true,
      action: () => {
        SvelteDialog.show(SetRateAccessCodeDialog, {bidsIds: selectedBidsReport.bidsIds})
          .catch(noop);
      }
    };
  }
}
