import {SvelteDialog} from 'root/v-app/rbServices';
import ViewBidMessagesDialog from './ViewBidMessagesDialog.svelte';
import SendBidMessagesDialog from './SendBidMessagesDialog.svelte';



export function SendMessages(handleActionResultFn) {
  return function (selectedBidsReport) {
    return {
      label: 'Send Messages',
      icon: 'question_answer',
      isAvailable: () => {
        /**
         * todo: depends on type:
         * if we implement private and public bids, user should be able to see private bids always and public ones only after bid is sent
         * otherwise, messages are going to be visible only after the bid is sent
         *
         * // TODO: implement both private and public
         */
        return true;
      },
      action: () => {
        SvelteDialog.show(SendBidMessagesDialog, {bidsIds: selectedBidsReport.bidsIds, side: selectedBidsReport.view.side});
      }
    }
  }
  /**
   * - Open Bid Messages
   */
}

export function viewMessages(bidId){
  SvelteDialog.show(ViewBidMessagesDialog, {bidId});
}

