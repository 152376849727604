/**
 * Created by DejanK on 2/8/2017.
 */
import { noop, cloneDeep, merge } from 'lodash'
import HTML from './edit-destination.html'
import './edit-destination.scss';
import { hasNotChanged } from '../../../../../../shared/tools/utils';
import { getAddressType } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { getValidationRules } from 'root/rfp-hotel/main/rfp/destinations/travel-destination-validation';
import currencies from 'vRoot/rfp-hotel/bid/rate-quick/inputs/currencies';

const NAME = 'editTravelDestination'

export { NAME, HTML, RfpEditDestinationController as Controller}

RfpEditDestinationController.$inject = ['NotificationUtils', 'RfpHotelRfpDestinationsRepository', 'rbDialog'];
function RfpEditDestinationController(notificationUtils, rfpHotelRfpDestinationsRepository, dialog){
  const vm = this;
  let form;
  let untouchedDestination

  vm.destinationRules = getValidationRules()
  vm.pageTitle = 'Edit Travel Destination';

  vm.cancel = dialog.cancel
  vm.onSubmit = onSubmit
  vm.onTypeChange = onTypeChange
  vm.activeTab = 'address';
  vm.currencies = currencies;

  vm.addRate = addRate;
  vm.removeRate = removeRate;
  vm.minDate = minDate;
  vm.showCaps = false;
  vm.onFormLoaded = onFormLoaded;

  vm.tabs = [
    {
      id: 'address',
      label: () => vm.destination.type === 'AIRPORT'? 'Destination Airport': 'Destination Address',
      show: () => true,
      valid() {
        const addressForm = form['$ctrl.addressForm'];
        return addressForm.$valid;
      },
    },
    {
      id: 'spend',
      label: () => 'Room Night Volumes',
      show: () => true,
      valid() {
        return form.estSpend.$valid && form.estRoomNights.$valid;
      },
    },
    {
      id: 'caps',
      label: () => 'Destination Rate Caps',
      show: () => vm.showCaps,
      valid() {
        const addressForm = form['$ctrl.addressForm'];
        const valid = addressForm.$valid && form.estSpend.$valid && form.estRoomNights.$valid && form.name.$valid;
        return form.$valid || !valid;
      }
    }
  ];

  vm.toggleCap = () => {
    vm.showCaps = !vm.showCaps;
    if(vm.activeTab === 'caps') {
      vm.activeTab = 'spend';
    }
  }

  $onInit();

  function $onInit() {
    notificationUtils.onLoad( () => { return rfpHotelRfpDestinationsRepository.getRfpTravelDestination(vm.rfpId, vm.destinationId) },
      vm, 'destination')
      .then(() => {
        vm.destination = merge({
            currency: 'USD',
            caps: [],
          },
          vm.destination.data
        );
        untouchedDestination = cloneDeep(vm.destination)
        vm.addressType = getAddressType(vm.destination.type)
        vm.showCaps = vm.destination.caps.length > 0;
      }, noop )
  }

  function onSubmit(formValid){
    if(!formValid) return;

    if(!vm.showCaps) {
      vm.destination.caps = [];
    }

    if(hasNotChanged(vm.destination, untouchedDestination)){
      vm.cancel()
    } else {
      vm.saving = 'saving'
      notificationUtils.onSave( () => {
        return rfpHotelRfpDestinationsRepository.updateRfpTravelDestination(vm.destination, vm.rfpId, vm.destination.type)
      })
        .then(dialog.hide, noop)
        .finally(() => { vm.saving = null })
    }
  }

  function onTypeChange(){
    vm.addressType = getAddressType(vm.destination.type)
    delete vm.destination.location
  }
  function addRate() {
    vm.destination.caps.push({
      startDate: minDate(vm.destination.caps.length),
      endDate: vm.rfp.specifications.programEndDate,
      rate: 0,
    });
  }

  function removeRate(index) {
    vm.destination.caps.splice(index, 1);
  }

  function minDate(index) {
    return index > 0? vm.destination.caps[index - 1].endDate: vm.rfp.specifications.programStartDate;
  }

  function onFormLoaded( f ) {
    form = f;
  }
}
