<script>
  import ErrorNotification from 'root/components/atoms/ErrorNotification.svelte';
  import {httpErrorHandler} from 'root/angular-injector-provider';

  export let error;

  let errorTitle, errorMessage;

  $: handleError(error)

  function handleError(e){
    // noinspection EqualityComparisonWithCoercionJS
    const message = e && e.status == 404 && e.data && e.data.message;

    switch (message) {
      case 'NOT_FOUND':
      case 'DELETED':
        errorTitle = 'Bid not found'
        errorMessage = 'Bid has been deleted.';
        break;
      case 'RESPONDED':
        errorTitle = 'Response Already Sent'
        errorMessage = 'Bid response is already sent.'
        break;
      case 'NOT_FINAL_AGREEMENT':
        errorTitle = 'Final Agreement Removed'
        errorMessage = 'This final agreement has been removed.'
        break;
      default:
        errorMessage = '';
        httpErrorHandler().handle(e);
    }
  }


</script>

<ErrorNotification
  title="Error"
  subtitle="{errorTitle || ''}"
  text="{errorMessage || ''}"
/>
