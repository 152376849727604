import HTML from './bid-response.html';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {FillResponseOnlyOnce} from 'auth/tutorials.service';
import saveResponseService from './save-response.service'

const
  // states
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR';

export { HTML, BidResponsePreloadController as Controller }

BidResponsePreloadController.$inject = ['NotificationUtils', 'RfpHotelBidRepository', '$state', 'CurrentUser', 'TutorialsService', 'MainAPI'];
function BidResponsePreloadController(utils, rfpHotelBidRepository, $state, currentUser, tutorials, mainAPI) {
  const
    vm = this;

  vm.isLoading = () => vm.state === LOADING;
  vm.isLoaded = () => vm.state === LOADED;

  $onInit();

  function $onInit() {
    vm.state = LOADING;

    rfpHotelBidRepository.getBidResponseDraft($state.params.bidId)
      .then(httpResponse => {
        const bid = httpResponse.data;
        vm.options = getOptions(bid);
        vm.bid = bid;
        vm.state = LOADED;
      })
      .catch(e => {
        vm.error = e;
        vm.state = ERROR;
      });
  }

  function getOptions(bid) {
    return {
      header: {
        pretitle: get(bid, 'specifications.name', ''),
        title: 'Respond to RFP',
        subtitle: get(bid, 'supplierCompany.name', ''),
      },
      onMount: () => {
        currentUser.get()
          .then(cu => !cu.isAccountType('CHAIN') && tutorials.show(FillResponseOnlyOnce))
          .catch(noop);
      },
      buttons: {
        cancel: {
          label: 'Cancel',
          action: saveResponseService.toBidManager
        },

        draft: {
          label: 'Save as Draft',
          action: answers => saveResponseService.saveDraftResponse(
            () => utils.onSave(() => mainAPI.saveDraftResponse(bid.id, answers))
          )
        },

        submit: {
          label: 'Send Response',
          action: response => saveResponseService.saveResponse(response, r => {
            const ignoreErrors = true;
            return utils.onSave(() => mainAPI.setBidResponse(bid.id, r.answers, ignoreErrors));
          })
        },
      }
    };
  }
}
