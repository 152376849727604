import {checkAndExecute} from 'root/api/helpers';

export default function HotelRfpBidBenchmarkRateAPI(api) {
  api.benchmarkRates = {
    list: (query) => api.server.post(`rfps/hotel/bids/benchmark-rates/query`, query),

    set: (bidId, data) => checkAndExecute(
      () => bidId,
      () => api.server.put(`rfps/hotel/bids/benchmark-rates/${bidId}`, data)),
  }
}
