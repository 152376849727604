/**
 * Created by DejanK on 01/09/2017.
 */
import { cloneDeep, merge } from 'lodash'
import HTML from '../../edit-destination/edit-destination.html'
import { getAddressType } from 'root/rfp-hotel/main/rfp/destinations/travel-destination';
import { getValidationRules } from 'root/rfp-hotel/main/rfp/destinations/travel-destination-validation';
import { hasNotChanged } from 'root/shared/tools/utils';
import currencies from 'vRoot/rfp-hotel/bid/rate-quick/inputs/currencies';

const NAME = 'editUploadedTravelDestination'

export { NAME, HTML, EditUploadedDestinationController as Controller}

EditUploadedDestinationController.$inject = [ 'rbDialog', 'Destination', '$scope' ]
function EditUploadedDestinationController( dialog, destination, $scope ) {
  const vm = this;
  let form = null;

  vm.cancel = dialog.cancel
  vm.onSubmit = onSubmit
  vm.onTypeChange = onTypeChange
  vm.onFormLoaded = onFormLoaded

  vm.activeTab = 'address';
  vm.currencies = currencies;

  vm.addRate = addRate;
  vm.removeRate = removeRate;
  vm.minDate = minDate;
  vm.showCaps = false;
  vm.tabs = [
    {
      id: 'address',
      label: () => vm.destination.type === 'AIRPORT'? 'Destination Airport': 'Destination Address',
      show: () => true,
      valid() {
        const addressForm = form['$ctrl.addressForm'];
        return addressForm.$valid;
      },
    },
    {
      id: 'spend',
      label: () => 'Room Night Volumes',
      show: () => true,
      valid() {
        return form.estSpend.$valid && form.estRoomNights.$valid;
      },
    },
    {
      id: () => 'caps',
      label: 'Destination Rate Caps',
      show: () => vm.showCaps,
      valid() {
        const addressForm = form['$ctrl.addressForm'];
        const valid = addressForm.$valid && form.estSpend.$valid && form.estRoomNights.$valid && form.name.$valid;
        return form.$valid || !valid;
      }
    }
  ];

  vm.toggleCap = () => {
    vm.showCaps = !vm.showCaps;
    if(vm.activeTab === 'caps') {
      vm.activeTab = 'spend';
    }
  }

  $onInit()

  function $onInit() {
    vm.pageTitle = 'Edit Travel Destination';
    vm.destination = merge({
        currency: 'USD',
        caps: [],
      },
      cloneDeep(destination)
    );
    vm.addressType = getAddressType(vm.destination.type)
    vm.destinationRules = getValidationRules()
    vm.showCaps = vm.destination.caps.length > 0;
  }

  function onSubmit(isValid){
    if(!vm.showCaps) {
      vm.destination.caps = [];
    }

    if(isValid){
      hasNotChanged(vm.destination, destination) ? vm.cancel() : dialog.hide(vm.destination)
    }
  }

  function onTypeChange(){
    vm.addressType = getAddressType(vm.destination.type)
    delete vm.destination.location
  }

  function onFormLoaded( f ) {
    $scope.$timeout(() => {
      f.$rbTouchAllFields();
      f.$$controls.reduce(
        (acc, {$$controls}) => $$controls && $$controls.length > 0? [
          ...acc,
          ...$$controls.filter(field  => !field.hasOwnProperty('$$controls'))
        ]: acc, []).forEach(field => field.$setTouched())
     }, 300)
    form = f;
  }

  function addRate() {
    vm.destination.caps.push({
      startDate: minDate(vm.destination.caps.length),
      endDate: vm.rfp.specifications.programEndDate,
      rate: 0,
    });
  }

  function removeRate(index) {
    vm.destination.caps.splice(index, 1);
  }

  function minDate(index) {
    return index > 0? vm.destination.caps[index - 1].endDate: vm.rfp.specifications.programStartDate;
  }
}
