<script>
  import { onMount } from 'svelte';
  import {mainAPI, notificationUtils} from 'root/angular-injector-provider';
  import RateAccessCodeEditor from 'root/rfp-hotel/main/bid/pages/final-agreement/RateAccessCodeEditor.svelte';

  export let bidId, cancel, hide;

  let stateReady, rateAccessCode = '';

  onMount(() => {
    notificationUtils().onLoad(() => mainAPI().getBidRateAccessCode(bidId))
      .then(r => {
        rateAccessCode = r.data || '';
        stateReady = true;
      })
  });


  function save(){
    notificationUtils().onSave(() => mainAPI().setBidRateAccessCode(bidId, { rateAccessCode }))
      .then(hide)
  }

</script>

<RateAccessCodeEditor bind:rateAccessCode
                      {stateReady}
                      {cancel}
                      {save}
/>
