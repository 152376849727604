<script>
  import TextArea from './TextArea.svelte';
  import { createEventDispatcher } from 'svelte';

  export let
    value = '',
    maxLength = 0,
    placeholder = '',
    readonly,
    maxHeight,
    error;

  const dispatchEvent = createEventDispatcher();

  function dispatch(e){
    if(!readonly) {
      value = maxLength ? e.detail.substring(0, maxLength) : e.detail;
      dispatchEvent(e.type, value);
    }
  }

</script>

<div class="Component" >
  <div class="Label" class:Error="{error}"><slot /></div>

  {#if maxLength && !readonly}
    <div class="Counter" class:Error="{error}">{`${value ? value.length : 0}/${maxLength}`}</div>
  {/if}

  <div class="TextArea" class:Error="{error}" style="{maxHeight? `max-height: ${maxHeight}; overflow:auto;`: ''}">
    <TextArea bind:value
              {placeholder}
              {readonly}
              on:input="{e => dispatch(e)}"
              on:change="{e => dispatch(e)}"
    />
  </div>
</div>


<style lang="stylus">.Component {
  position: relative;
  padding-top: 15px;
}
.Label {
  position: absolute;
  top: 7px;
  z-index: 1;
  background: #fff;
  font-size: 12px;
  line-height: 12px;
  color: #b0bec5;
  padding: 0 5px;
  left: 10px;
}
.Label.Error {
  color: #f15a24;
}
.Counter {
  position: absolute;
  top: 7px;
  z-index: 1;
  background: #fff;
  font-size: 12px;
  line-height: 12px;
  color: #b0bec5;
  padding: 0 5px;
  right: 10px;
}
.Counter.Error {
  color: #f15a24;
}
.TextArea {
  border: 1px solid #cfd8dc;
  border-radius: 6px;
}
.TextArea :global(.TextAreaInputContainer) {
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  font-weight: normal;
  font-family: "Noto Sans", sans-serif;
  color: #546e7a;
  padding: 10px 10px 5px;
}
.TextArea.Error :global(.TextAreaInputContainer) {
  border-color: #f15a24;
}
</style>

