/**
 * Created by DejanK on 4/4/2017.
 */
export default RfpHotelRfpDestinationsRepository

RfpHotelRfpDestinationsRepository.$inject = ['MainServer', '$q', 'MainAPI'];
function RfpHotelRfpDestinationsRepository(mainServer, $q, MainAPI){
  const $repo = this;

  $repo.getRfpTravelDestinations =
    (rfpId) => {return rfpId
      ? mainServer.get(`rfps/${rfpId}/travel-destinations`)
      : $q.reject({status: 404})};

  $repo.createRfpTravelDestination =
    (travelDestination, rfpId, type) => {
      const t = type ? type.toLowerCase() : '';
      return rfpId && ['office', 'city', 'airport'].includes(t)
        ? mainServer.post(`rfps/${rfpId}/travel-destinations/${t}`, travelDestination)
        : $q.reject({status: 404})};

  $repo.createRfpTravelDestinations =
    (travelDestinations, rfpId) => {
      return rfpId
        ? mainServer.post(`rfps/${rfpId}/travel-destinations/bulk`, {travelDestinations: travelDestinations})
        : $q.reject({status: 404})};

  $repo.updateRfpTravelDestination =
    (travelDestination, rfpId, type) => {
      const t = type ? type.toLowerCase() : '';
      return rfpId && travelDestination && travelDestination.id && ['office', 'city', 'airport'].includes(t)
        ? mainServer.put(`rfps/${rfpId}/travel-destinations/${t}/${travelDestination.id}`, travelDestination)
        : $q.reject({status: 404})};

  $repo.deleteRfpTravelDestination =
    (destinationId, rfpId) => {
      return rfpId && destinationId
        ? mainServer.delete(`rfps/${rfpId}/travel-destinations/${destinationId}`)
        : $q.reject({status: 404})};

  $repo.getRfpTravelDestination =
    (rfpId, destinationId) => {return rfpId && destinationId
      ? mainServer.get(`rfps/${rfpId}/travel-destinations/${destinationId}`)
      : $q.reject({status: 404})};

  $repo.getRfpTravelDestinationNoAuth =
    (rfpId, destinationId) => {return rfpId && destinationId
      ? mainServer.get(`rfps/${rfpId}/travel-destinations/${destinationId}/no-auth`)
      : $q.reject({status: 404})};

  $repo.saveDestinationFilter =
    (rfpId, destinationId, filter) => {return rfpId && destinationId && filter
      ? mainServer.put(`rfps/${rfpId}/travel-destinations/${destinationId}/filter`, filter)
      : $q.reject({status: 404})};

  $repo.setRfpTravelDestinationsDefaultFilter = (rfpId, filter) => {return rfpId && filter
    ? mainServer.put(`rfps/${rfpId}/set-default-filter`, filter)
    : $q.reject({status: 404})
  };

  $repo.listChains = MainAPI.listChains;
}
